import React from 'react';
import {
  faUser,
  faGrip,
  faTruck,
  faLink,
  faGauge,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoWindow } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';

import { config } from '../utils/config';
import { markerFormats, gridStyle, setColorByCategoryAndDate } from '../utils/markers';

const MarkerInfo = ({ anchor, data, unitSystem }) => {
  if (!data) return null;
  return (
    <InfoWindow anchor={anchor} headerDisabled zIndex={10000} pixelOffset={[0, 20]}>
      <div style={gridStyle}>
        <FontAwesomeIcon icon={faUser} size="1x" />
        <b>{markerFormats.getDriverName(data)}</b>
      </div>
      <div style={gridStyle}>
        <FontAwesomeIcon icon={faGrip} size="1x" />
        {markerFormats.getDutyStatus(data)}
      </div>
      <div style={gridStyle}>
        <FontAwesomeIcon icon={faTruck} size="1x" />
        {markerFormats.getVehicleName(data)}
      </div>
      <div style={gridStyle}>
        <FontAwesomeIcon icon={faLink} size="1x" />
        {markerFormats.getTrailerNames(data)}
      </div>
      <div style={gridStyle}>
        <FontAwesomeIcon icon={faGauge} size="1x" />
        {markerFormats.getSpeedValue(data, unitSystem)}
      </div>
      <div style={gridStyle}>
        <FontAwesomeIcon icon={faVideo} size="1x" />
        {markerFormats.getCameraInfo(data)}
      </div>
      <div
        style={{
          ...gridStyle,
          backgroundColor: setColorByCategoryAndDate(data),
          color: '#FFF',
          padding: 5,
        }}
      >
        {config.HUMANIZE_MARKER_DATES
          ? markerFormats.getHumanizedDate(data)
          : markerFormats.getNonHumanizedDate(data)}
      </div>
    </InfoWindow>
  );
};

MarkerInfo.propTypes = {
  anchor: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  unitSystem: PropTypes.string,
};

export default MarkerInfo;
