import { DateTime, Duration } from 'luxon';

const createDateTime = (date) => (date ? DateTime.fromISO(date) : DateTime.now());

const formatDateTime = (date, format) => {
  if (date) {
    return DateTime.fromISO(date).toFormat(format);
  }
  return null;
};

const humanizeDate = (dateString) => {
  const date = new Date(dateString);
  const dateTime = DateTime.fromJSDate(date);

  return dateTime.toRelative();
};

const deHumanizeDate = (dateString) => {
  const dt = DateTime.fromISO(dateString);

  const now = DateTime.now();

  if (dt.hasSame(now, 'day')) {
    return `Today at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  if (dt.plus({ days: 1 }).hasSame(now, 'day')) {
    return `Yesterday at ${dt.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  return dt.toLocaleString(DateTime.DATE_FULL);
};

const humanizeDateStatus = (dateString) => {
  const date = new Date(dateString);
  const end = DateTime.fromJSDate(date);

  const start = DateTime.now();
  const duration = start.diff(end);

  if (duration.as('weeks') >= 1) {
    return `${Math.floor(duration.as('weeks'))}w`;
  }
  if (duration.as('days') >= 1) {
    return `${Math.floor(duration.as('days'))}d`;
  }
  if (duration.as('hours') >= 1) {
    return `${Math.floor(duration.as('hours'))}h`;
  }
  const minutes = Math.floor(duration.as('minutes'));
  return minutes > 0 ? `${minutes}m` : 'now';
};

const formatDuration = (durationInSeconds, format) => {
  const duration = Duration.fromObject({ seconds: durationInSeconds });
  return duration.toFormat(format);
};

export {
  createDateTime,
  deHumanizeDate,
  formatDateTime,
  formatDuration,
  humanizeDate,
  humanizeDateStatus,
};
