import { Hls } from 'hls.js';
import { noop, toUpper } from 'lodash';

import { apiGet } from './api';

let videoInstance = null;
let hlsInstance = null;

function closeResourceWindow(setResource, defaultResourceState) {
  if (hlsInstance) {
    hlsInstance.detachMedia();
    hlsInstance.destroy();
  }

  if (videoInstance && videoInstance.removeEventListener) {
    videoInstance.removeEventListener('loadeddata', noop);
  }
  if (setResource) {
    setResource(defaultResourceState);
  }
}

const getVideo = ({ row, api, token, setResource, defaultResourceState }) => {
  if (setResource) {
    setResource({
      loading: true,
      message: 'Connecting to camera...',
      name: row.name,
      show: true,
      type: 'LIVESTREAM-VIDEO',
    });
  }

  const videoUrl = `${api.baseUrl}cameras/liveStreamingLocations/?vehicleKey=${row.vehicleKey}&provider=${row.provider}&cameraId=${row.id}`;
  apiGet({ url: videoUrl, token })
    .then((response) => {
      if (response && response.hls) {
        videoInstance = document.getElementById('videoPlayer');

        if (Hls.isSupported()) {
          hlsInstance = new Hls();

          hlsInstance.loadSource(response.hls);
          hlsInstance.attachMedia(videoInstance);

          let bufferCount = 0;
          let errorCount = 0;

          hlsInstance.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
              errorCount += 1;
              bufferCount = 0;

              if (errorCount > 5) {
                if (setResource) {
                  setResource({
                    loading: false,
                    message: 'This resource is not available. Please try again later...',
                    name: row.name,
                    show: true,
                    type: 'LIVESTREAM-VIDEO',
                  });
                }

                setTimeout(() => {
                  if (setResource) {
                    setResource(defaultResourceState);
                  }
                  hlsInstance.destroy();
                }, 500);
                return;
              }

              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR: // trying to recover from network error
                  if (setResource) {
                    setResource({
                      loading: false,
                      message:
                        'We are facing network issues. Please wait while we are recovering...',
                      name: row.name,
                      show: true,
                      type: 'LIVESTREAM-VIDEO',
                    });
                  }
                  hlsInstance.loadSource(response.hls);
                  hlsInstance.attachMedia(videoInstance);
                  hlsInstance.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR: // try to recover from media error
                  hlsInstance.loadSource(response.hls);
                  hlsInstance.attachMedia(videoInstance);
                  hlsInstance.recoverMediaError();
                  if (setResource) {
                    setResource({
                      loading: false,
                      message:
                        'We are facing streaming issues. Please wait while we are recovering...',
                      name: row.name,
                      show: true,
                      type: 'LIVESTREAM-VIDEO',
                    });
                  }
                  break;
                default: // cannot recover
                  if (setResource) {
                    setResource({
                      loading: false,
                      message: 'This resource is not available. Please try again later...',
                      name: row.name,
                      show: true,
                      type: 'LIVESTREAM-VIDEO',
                    });
                  }

                  setTimeout(() => {
                    if (setResource) {
                      setResource(defaultResourceState);
                    }
                    hlsInstance.destroy();
                  }, 500);
                  break;
              }
            }
          });

          hlsInstance.on(Hls.Events.BUFFER_APPENDED, () => {
            bufferCount += 1;

            if (bufferCount > 1 && bufferCount < 16 && setResource) {
              setResource({
                loading: false,
                message: `Closing this window in ${30 - bufferCount * 2} seconds...`,
                name: row.name,
                show: true,
                type: 'LIVESTREAM-VIDEO',
              });
            }

            if (hlsInstance && bufferCount >= 16) {
              videoInstance.removeEventListener('loadeddata', noop);
              bufferCount = 0;

              setTimeout(() => {
                if (setResource) {
                  setResource(defaultResourceState);
                }
                hlsInstance.destroy();
              }, 500);
            }
          });

          if (videoInstance && !Object.prototype.hasOwnProperty.call(videoInstance, 'loadeddata')) {
            videoInstance.addEventListener('loadeddata');
          }
        }
      }
    })
    .catch(() => {
      // This call seems to fail sometimes for no apparent reason before recover and return data,
      // so we will just add this empty catch to avoid javascript unhandled exceptions
    });
};

const getSnapshot = ({ row, api, token, setResource }) => {
  setResource({
    loading: true,
    message: 'Requesting snapshot...',
    name: row.name,
    show: true,
    type: 'SNAPSHOT',
  });

  const picUrl = `${api.baseUrl}cameras/liveSnapshotLocations/?vehicleKey=${row.vehicleKey}&provider=${row.provider}&cameraId=${row.id}`;
  apiGet({ url: picUrl, token })
    .then((response) => {
      if (response && response.url) {
        setResource({
          loading: false,
          message: null,
          name: row.name,
          url: response.url,
          show: true,
          type: 'SNAPSHOT',
        });
      }
    })
    .catch(() => {
      setResource({
        loading: false,
        message: 'An error occurred while requesting snapshot, try again later...',
        name: row.name,
        url: null,
        show: true,
        type: 'SNAPSHOT',
      });
    });
};

const getCameraData = ({ vehicleKey, api, token, resource, setResource, setTile }) => {
  setResource({ ...resource, loading: true });

  const cameraUrl = `${api.baseUrl}cameras?vehicleKey=${vehicleKey}`;
  apiGet({ url: cameraUrl, token })
    .then((response) => {
      if (response && response.cameras) {
        setResource({ ...resource, loading: false, cameras: response.cameras });
        setTile('summary');
      }
    })
    .catch(() => {
      setResource({ ...resource, loading: false });
      setTile('summary');
    });
};

const getRecordedFiles = ({ fileLocation, api, token, setResource }) => {
  // if we have any other video player streaming we will close it
  closeResourceWindow();

  setResource({
    loading: true,
    message: 'Please wait while we are retrieving this recorded resource...',
    name: fileLocation.name,
    show: true,
    url: null,
    type: `RECORDED-${toUpper(fileLocation.fileType)}`,
  });

  const recUrl = `${api.baseUrl}videoEventFileLocations/${fileLocation.fileLocationId}`;
  apiGet({ url: recUrl, token })
    .then((response) => {
      if (!response) {
        setResource({
          loading: false,
          message:
            'We were unable to retrieve the recorded resource, please try again or contact support if this issue persists.',
          name: fileLocation.name,
          show: true,
          url: null,
        });
        return;
      }

      if (response.url) {
        setResource({
          loading: false,
          message: null,
          name: fileLocation.name,
          show: true,
          url: response.url,
          type: `RECORDED-${toUpper(fileLocation.fileType)}`,
        });
      }
    })
    .catch(() => {
      setResource({
        loading: false,
        message: 'An error occurred while requesting the recorded resource, try again later...',
        name: fileLocation.name,
        url: null,
      });
    });
};

export { getVideo, getSnapshot, getCameraData, getRecordedFiles, closeResourceWindow };
