import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { createDateTime, formatDateTime, formatDuration } from '../../utils/dates';

import '../../css/Tiles.css';

export const Cycle = ({ logData, entity }) => {
  const { data, isLoading } = logData || {};
  const { driver, cycleOnDutyRemaining, cycleOnDutyTime, cycleName } = data || {};

  const todayDate = formatDateTime(createDateTime().toISO(), 'yyyyMMdd');

  if (!entity?.driver) {
    return null;
  }

  return (
    <div className="Tiles__tile">
      <h4>Cycle Summary</h4>
      <div className="Tiles__tile__content">
        {isLoading ? (
          <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
        ) : (
          <>
            <div className="Tiles__tile__content__item cycle">
              <strong>{formatDuration(cycleOnDutyRemaining, 'h:mm')}</strong>
              <span>Cycle Remaining</span>
            </div>
            <div className="Tiles__tile__content__item cycle">
              <strong>{formatDuration(cycleOnDutyTime, 'h:mm')}</strong>
              <span>Cycle Total</span>
            </div>
            <div className="Tiles__tile__content__item">
              <p>{cycleName}</p>
            </div>
          </>
        )}
      </div>
      <div className="Tiles__tile__footer">
        <a
          href={`#/reports/driverLogSummary/default/driverLogs/${driver?.key}-${todayDate}/default`}
          target="_blank"
          rel="noreferrer"
        >
          View Details
        </a>
      </div>
    </div>
  );
};

Cycle.propTypes = {
  logData: PropTypes.shape({
    data: PropTypes.object,
    isLoading: PropTypes.bool,
  }),
  entity: PropTypes.object,
};
