import React, { useState, useEffect, useRef, useContext } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { EntityContext } from '../context/EntityContext';
import { STORAGE_KEYS, DEFAULT_LOCATION } from '../utils/constants';

const AutoComplete = () => {
  const { entity } = useContext(EntityContext);

  const [selectedPlace, setSelectedPlace] = useState(null);
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');

  const showSearchBar = !!localStorage.getItem(STORAGE_KEYS.settings).includes('showSearchBar');

  const map = useMap();

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      setSelectedPlace(placeAutocomplete.getPlace());
    });
  }, [placeAutocomplete]);

  useEffect(() => {
    if (!map || !selectedPlace) return;

    if (selectedPlace.geometry?.viewport) {
      map.fitBounds(selectedPlace.geometry?.viewport);
    }
  }, [map, selectedPlace]);

  if (!showSearchBar) return null;

  return (
    <div className="autocomplete-container">
      <input ref={inputRef} />
      <span
        className="autocomplete-suffix"
        onClick={() => {
          inputRef.current.value = '';
          const location = entity?.vehicle?.location || DEFAULT_LOCATION;
          map.panTo(location);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </span>
    </div>
  );
};

export default AutoComplete;
