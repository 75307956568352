import React, { useContext, useEffect, useState } from 'react';
import {
  faCaretRight,
  faCaretDown,
  faArrowsRotate,
  faRoad,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { upperFirst } from 'lodash';

import { TilesContext } from '../context/TilesContext';
import { createDateTime, formatDateTime, deHumanizeDate } from '../utils/dates';
import { apiGet } from '../utils/api';
import { CATEGORIES, STORAGE_KEYS } from '../utils/constants';

import Tiles from './Tiles';
import Messenger from './Messenger';

import '../css/BottomPanel.css';

const DEFAULT_STATE = { isLoading: true, data: [] };

const fetchData = ({
  entity,
  api,
  token,
  currentSystemOu,
  setLogData,
  setShiftData,
  setInspectionData,
  setExceptionData,
  setCameraData,
  groupVehicleAndAttachedTrailers,
  selectedTargetId,
}) => {
  const todayFormatted = formatDateTime(createDateTime(), 'yyyy-MM-dd');
  const todayUtc = createDateTime().toUTC();
  const yesterday = createDateTime().minus({ days: 1 }).toUTC();

  const lookUps = {
    vehicleKey: entity?.vehicle?.vehicle,
    asset: groupVehicleAndAttachedTrailers ? selectedTargetId : entity.api?.asset,
  };

  const cameraUrl = `${api.baseUrl}cameras?vehicleKey=${lookUps.vehicleKey}`;
  const logUrl = `${api.originUrl}/api/driverLogs/detailed/${entity?.driver?.key}/${todayFormatted}`;
  const shiftUrl = `${api.originUrl}/api/documents?date=${todayFormatted}&driver=${entity?.driver?.key}&type=activities.ShiftDocument`;
  const vehicleInspectUrl = `${api.originUrl}/api/inspections/latestVehicleInspections/${lookUps.asset}`;
  const inspectorInspectUrl = `${api.originUrl}/api/inspections/latestInspectorInspections/${entity.api?.driverLog}`;
  const exceptionUrl = `${api.baseUrl}exceptions?ouKey=${currentSystemOu?.key}&startDate=${yesterday}&endDate=${todayUtc}`;

  if (entity.api?.driverLog) {
    apiGet({ url: logUrl, token }).then((data) => setLogData({ data, isLoading: false }));
    apiGet({ url: shiftUrl, token }).then((data) => setShiftData({ data, isLoading: false }));
  } else {
    setLogData({ data: [], isLoading: false });
    setShiftData({ data: [], isLoading: false });
  }

  if (entity && currentSystemOu?.key) {
    const inspectUrl =
      entity.category === CATEGORIES.DRIVER ? inspectorInspectUrl : vehicleInspectUrl;

    apiGet({ url: inspectUrl, token }).then((data) =>
      setInspectionData({ data, isLoading: false })
    );

    apiGet({ url: exceptionUrl, token }).then((resp) =>
      setExceptionData({ data: resp?.data, isLoading: false })
    );

    if (entity.vehicle?.camera && entity.vehicle?.vehicle && lookUps.vehicleKey) {
      apiGet({ url: cameraUrl, token }).then((data) => setCameraData({ data, isLoading: false }));
    }
  }
};

const BottomPanel = () => {
  const { entity, api, token, currentSystemOu } = useContext(TilesContext);
  const [collapse, setCollapse] = useState(false);
  const [tile, setTile] = useState('summary');

  // Data states
  const [cameraData, setCameraData] = useState(DEFAULT_STATE);
  const [exceptionData, setExceptionData] = useState(DEFAULT_STATE);
  const [inspectionData, setInspectionData] = useState(DEFAULT_STATE);
  const [logData, setLogData] = useState(DEFAULT_STATE);
  const [shiftData, setShiftData] = useState(DEFAULT_STATE);
  const [selectedTrailerId, setSelectedTrailerId] = useState(null);

  const todayUtc = createDateTime().toUTC();

  const groupVehicleAndAttachedTrailers = localStorage
    .getItem(STORAGE_KEYS.settings)
    .includes('groupVehiclesAndAttachedTrailersMarkersForTrailerCategory');

  useEffect(() => {
    // Reset data states when entity context changes
    // This is to prevent stale data from being rendered
    if (!entity) {
      // Reset all data states
      setCameraData(DEFAULT_STATE);
      setExceptionData(DEFAULT_STATE);
      setInspectionData(DEFAULT_STATE);
      setLogData(DEFAULT_STATE);
      setShiftData(DEFAULT_STATE);
      setCollapse(false);
      return;
    }

    const selectedTargetId = selectedTrailerId || entity.trailers?.[0]?.vehicle;

    fetchData({
      entity,
      api,
      token,
      currentSystemOu,
      setLogData,
      setShiftData,
      setInspectionData,
      setExceptionData,
      setCameraData,
      groupVehicleAndAttachedTrailers,
      selectedTargetId,
    });
  }, [entity]);

  useEffect(() => {
    const selectedTargetId = selectedTrailerId;

    if (!selectedTargetId) return;

    fetchData({
      entity,
      api,
      token,
      currentSystemOu,
      setLogData,
      setShiftData,
      setInspectionData,
      setExceptionData,
      setCameraData,
      groupVehicleAndAttachedTrailers,
      selectedTargetId,
    });
  }, [selectedTrailerId]);

  if (!entity) return null;

  if (collapse) {
    return (
      <div className="BottomPanel BottomPanel-collapsed">
        <div className="wrapper">
          <FontAwesomeIcon icon={faRoad} fontSize={14} />
          <h4>{entity?.title}</h4>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faCaretRight}
            fontSize={14}
            onClick={() => setCollapse(!collapse)}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className="BottomPanel"
      style={entity.driver ? { gridTemplateColumns: '40% 60%' } : { gridTemplateColumns: '100%' }}
    >
      {entity.driver && (
        <div className="BottomPanel__panel">
          <Messenger />
        </div>
      )}

      <div className="BottomPanel__panel">
        {entity?.category === CATEGORIES.TRAILERS &&
          entity?.trailers.length > 1 &&
          groupVehicleAndAttachedTrailers && (
            <div className="BottomPanel__panel__header">
              <div className="BottomPanel__panel__header__tabs">
                {entity?.trailers.map(({ vehicleId, vehicle }, index) => (
                  <button
                    key={vehicle}
                    type="button"
                    disabled={vehicle === selectedTrailerId || (!selectedTrailerId && index === 0)}
                    onClick={() => {
                      setSelectedTrailerId(vehicle);
                    }}
                  >
                    {upperFirst(vehicleId)}
                  </button>
                ))}
              </div>
            </div>
          )}
        <div className="BottomPanel__panel__header">
          <div className="BottomPanel__panel__header__tabs">
            <button
              type="button"
              disabled={tile === CATEGORIES.SUMMARY}
              onClick={() => {
                setTile(CATEGORIES.SUMMARY);
              }}
            >
              {upperFirst(CATEGORIES.SUMMARY)}
            </button>
            {entity.driver && (
              <button
                type="button"
                disabled={tile === CATEGORIES.DRIVER_LOGS}
                onClick={() => {
                  setTile(CATEGORIES.DRIVER_LOGS);
                }}
              >
                {upperFirst(CATEGORIES.DRIVER_LOGS)}
              </button>
            )}
            <button
              type="button"
              disabled={tile === CATEGORIES.INSPECTIONS}
              onClick={() => {
                setTile(CATEGORIES.INSPECTIONS);
              }}
            >
              {upperFirst(CATEGORIES.INSPECTIONS)}
            </button>
            <button
              type="button"
              disabled={tile === CATEGORIES.CURRENT_SHIFT}
              onClick={() => {
                setTile(CATEGORIES.CURRENT_SHIFT);
              }}
            >
              {upperFirst(CATEGORIES.CURRENT_SHIFT)}
            </button>
            {entity.vehicle?.camera && (
              <button
                type="button"
                disabled={tile === CATEGORIES.CAMERAS}
                onClick={() => {
                  setTile(CATEGORIES.CAMERAS);
                }}
              >
                {upperFirst(CATEGORIES.CAMERAS)}
              </button>
            )}
          </div>
          <div className="BottomPanel__panel__header__info">
            <span>{deHumanizeDate(todayUtc)}</span>
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faArrowsRotate}
              fontSize={14}
              onClick={() => {
                setCameraData(DEFAULT_STATE);
                setExceptionData(DEFAULT_STATE);
                setInspectionData(DEFAULT_STATE);
                setLogData(DEFAULT_STATE);
                setShiftData(DEFAULT_STATE);

                setTimeout(() => {
                  fetchData({
                    entity,
                    api,
                    token,
                    currentSystemOu,
                    setLogData,
                    setShiftData,
                    setInspectionData,
                    setExceptionData,
                    setCameraData,
                  });
                }, 500);
              }}
              className={inspectionData.isLoading ? 'fa-spin' : ''}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer' }}
              icon={faCaretDown}
              fontSize={14}
              onClick={() => setCollapse(!collapse)}
            />
          </div>
        </div>
        <div className="BottomPanel__panel__content">
          <Tiles
            tile={tile}
            setTile={setTile}
            data={{
              cameraData,
              exceptionData,
              inspectionData,
              logData,
              shiftData,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BottomPanel;
