/* eslint-disable no-undef */
import React, { createContext, useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, uniq, uniqBy } from 'lodash';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import { apiGet, apiPost } from '../utils/api';
import { config } from '../utils/config';
import {
  CATEGORIES,
  DEFAULT_LOCATION,
  DEFAULT_ZOOM,
  DEFAULT_UNSELECTED_ZOOM,
  STORAGE_KEYS,
} from '../utils/constants';
import { setFilterByCategory } from '../utils/filters';
import { createMapDataset } from '../utils/markers';
import { createDateTime } from '../utils/dates';
import { deepMergeEntities } from '../utils/entities';
import { formatUnit } from '../utils/formatters';
import { getRolesByUserMemberships } from '../utils/roles';

function showPolygonInfo({ infoWindow }, event, map, row, unitSystem) {
  const content = `<div class="info-window">
    <div class="info-window__header"><h4>${row.name}</h4></div>
    <div class="info-window__content">
        ${row.typeName ? `<p>Type: ${row.typeName}</p>` : ''}
        ${
          row.speedLimit?.limit
            ? `<p>Speed Limit: ${formatUnit('speed', row.speedLimit?.limit, unitSystem)} </p>`
            : ''
        }
    </div>
  </div>`;

  infoWindow.setContent(content);
  infoWindow.setPosition(event.latLng);
  infoWindow.setOptions({ pixelOffset: new google.maps.Size(0, -10) });
  infoWindow.open(map);
}

function clearPolygons(polygons) {
  polygons.forEach((polygon) => {
    polygon.setMap(null);
  });
}

const now = createDateTime();
const oneYearAgo = now.minus({ years: 1 });
const oneDayAgo = now.minus({ days: 1 });

const initialParams = {
  since: oneYearAgo.toUTC(),
  start: oneDayAgo.toUTC(),
  end: now.toUTC(),
};

const EntityContext = createContext();

const EntityProvider = ({ children, values }) => {
  const { currentSystemOu, currentSystemOus, currentSystemUser, api, token, dispatch } = values;
  const fleetTrackingSelection = localStorage.getItem(STORAGE_KEYS.selection);
  const map = useMap();

  const [assetTypes, setAssetTypes] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [category, setCategory] = useState(CATEGORIES.DRIVER);
  const [entity, setEntity] = useState(null);
  const [filteredAssetTypes, setFilteredAssetTypes] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredOus, setFilteredOus] = useState([]);
  const [hideInactive, setHideInactive] = useState(true);
  const [locations, setLocations] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [mainDataLoading, setMainDataLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [trackedData, setTrackedData] = useState({ driverActivity: [], vehicleLocations: [] });

  const polygonsRef = useRef([]);

  const users =
    trackedData?.driverActivity?.map(({ key, driverName }) => ({ key, fullName: driverName })) ||
    [];

  useEffect(() => {
    let isMounted = true;

    const fetchData = async (params) => {
      if (!isMounted) return [];

      const { since, start, end } = params;

      const ftUrl = `${api.originUrl}/api/fleettracking/trackedData?since=${since}`;
      const msgUrl = `${api.originUrl}/api/messaging/messages${
        start && end ? `?end=${end}&start=${start}` : ''
      }`;

      const ftData = await apiGet({ url: ftUrl, token });
      const msgData = await apiGet({ url: msgUrl, token });

      return [ftData, msgData];
    };

    const locationUrl = `${api.originUrl}/api/admin/locations`;
    const locationTypeUrl = `${api.originUrl}/api/admin/locationTypes`;
    const assetTypeUrl = `${api.originUrl}/api/admin/assetTypes?deleted=false`;
    const bookmarkUrl = `${api.originUrl}/api/fleettracking/bookmarks`;

    setMainDataLoading(true);

    fetchData(initialParams).then((responses) => {
      setMainDataLoading(false);
      setTrackedData(responses[0]);
      setMessages(responses[1]);
    });

    apiGet({ url: locationUrl, token }).then(setLocations);
    apiGet({ url: assetTypeUrl, token }).then(setAssetTypes);
    apiGet({ url: locationTypeUrl, token }).then((data) => {
      const mappedGeoZones = uniq(data?.map(({ key }) => key));

      try {
        const settingListOptions = JSON.parse(localStorage.getItem(STORAGE_KEYS.settings));

        if (isNil(settingListOptions) || isEmpty(settingListOptions)) {
          localStorage.setItem(
            STORAGE_KEYS.settings,
            JSON.stringify([
              'clusterNearbyVehicles',
              'showVehicleLabels',
              'showLocationLabels',
              ...mappedGeoZones,
            ])
          );
        }

        setTimeout(() => {
          const filteredGeozones = mappedGeoZones.filter((locationType) =>
            settingListOptions.includes(locationType)
          );

          setFilteredLocations(filteredGeozones);
        }, 100);
      } catch (error) {
        localStorage.setItem(
          STORAGE_KEYS.settings,
          JSON.stringify([
            'clusterNearbyVehicles',
            'showVehicleLabels',
            'showLocationLabels',
            ...mappedGeoZones,
          ])
        );

        setFilteredLocations(mappedGeoZones);
      }

      setLocationTypes(data);
    });
    apiGet({ url: bookmarkUrl, token }).then(setBookmarks);

    const interval = setInterval(() => {
      const today = createDateTime();
      const yesterday = today.minus({ days: 1 });
      const thirtyOneSecondsAgo = today.minus({ seconds: 31 });
      const updatedSince = thirtyOneSecondsAgo.toUTC();

      const updatedParams = {
        since: updatedSince,
        end: today.toUTC(),
        start: yesterday.toUTC(),
      };

      fetchData(updatedParams)
        .then((responses) => {
          setTrackedData((prevState) => {
            if (!isMounted) return null;

            return {
              driverActivity: deepMergeEntities(
                prevState.driverActivity,
                responses[0]?.driverActivity,
                'key'
              ),
              vehicleLocations: deepMergeEntities(
                prevState.vehicleLocations,
                responses[0]?.vehicleLocations,
                'vehicle'
              ),
            };
          });

          setMessages((prevState) => {
            if (!isMounted) return [];

            return prevState.concat(responses[1]);
          });
        })
        .catch((error) => {
          console.error('BE ERROR', error);
          return null;
        });
    }, 30000);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, []);

  const showTrafficOverlay = localStorage
    .getItem(STORAGE_KEYS.settings)
    .includes('showTrafficOverlay');

  const trafficLayerLib = useMapsLibrary('maps');
  const trafficLayer = useMemo(
    () => trafficLayerLib && new trafficLayerLib.TrafficLayer(),
    [trafficLayerLib]
  );

  useEffect(() => {
    if (map && trafficLayer && showTrafficOverlay) {
      trafficLayer.setMap(map);
    }

    if (map && trafficLayer && !showTrafficOverlay) {
      trafficLayer.setMap(null);
    }
  }, [showTrafficOverlay, trafficLayer]);

  const unitSystem = useMemo(
    () =>
      currentSystemUser?.unitSystem?.toLowerCase() ||
      currentSystemOu?.unitSystem?.toLowerCase() ||
      'metric',
    [currentSystemUser, currentSystemOu]
  );

  const currentUserRoles = useMemo(
    () => getRolesByUserMemberships(currentSystemUser, currentSystemOus),
    [currentSystemUser, currentSystemOus]
  );

  const currentOUs = useMemo(() => currentSystemOus.map(({ key }) => key), [currentSystemOus]);

  const groupVehicleAndAttachedTrailers = localStorage
    .getItem(STORAGE_KEYS.settings)
    .includes('groupVehiclesAndAttachedTrailersMarkersForTrailerCategory');

  const filteredDataset = useMemo(() => {
    const payload = { ...trackedData, messages };
    if (config.SHOW_CONSOLE_LOGS) {
      console.groupCollapsed('FLEET TRACK DATA INFO UPDATE');
      console.info('- Data received from BE endpoints', payload);
    }

    const dataset = createMapDataset(
      payload,
      currentSystemOu,
      filteredOus,
      filteredAssetTypes,
      currentSystemUser,
      groupVehicleAndAttachedTrailers
    );
    const result = dataset?.filter((row) =>
      setFilterByCategory(row, category, search, hideInactive)
    );

    if (config.SHOW_CONSOLE_LOGS) {
      console.info(`- Dataset created from received trackedData`, dataset);
      console.info(`- Dataset filtered for ${category} category`, result);
      console.info(`- Markers being rendered based on filtered dataset`, result);
      console.groupEnd();
    }

    return result;
  }, [
    trackedData.driverActivity,
    trackedData.vehicleLocations,
    messages,
    category,
    search,
    hideInactive,
    filteredOus,
    filteredAssetTypes,
    groupVehicleAndAttachedTrailers,
  ]);

  const polygons = useMemo(() => {
    if (map) {
      clearPolygons(polygonsRef.current);
    }

    try {
      const options = JSON.parse(localStorage.getItem(STORAGE_KEYS.settings));
      const filteredLocs = locations.filter((location) => options.includes(location?.type));

      const items = uniqBy(
        filteredLocs
          .filter((row) => row && row.locations)
          .map((row) => {
            const polygon = new google.maps.Polygon({
              key: row.key,
              paths: row.locations.map(
                (location) => new google.maps.LatLng(location.latitude, location.longitude)
              ),
              strokeColor: '#000000',
              strokeOpacity: 0.5,
              strokeWeight: 1,
              fillColor: row.color,
              fillOpacity: 0.35,
              zIndex: filteredLocs.length - filteredLocs.indexOf(row),
              infoWindow: new google.maps.InfoWindow({ headerDisabled: true }),
              data: row,
            });
            polygon.addListener('mouseover', (event) =>
              showPolygonInfo(polygon, event, map, row, unitSystem)
            );
            polygon.addListener('mousemove', (event) =>
              polygon?.infoWindow?.setPosition(event.latLng)
            );
            polygon.addListener('mouseout', () => polygon?.infoWindow?.close());
            if (currentUserRoles.isSuperAdmin) {
              polygon.addListener('click', () => setSelectedPolygon(polygon));
            }
            polygon.setMap(map);
            return polygon;
          }),
        'key'
      );

      polygonsRef.current = items;

      return items;
    } catch (error) {
      return [];
    }
  }, [locations, filteredLocations, currentUserRoles]);

  useEffect(() => {
    setFilteredOus(currentOUs);
  }, [currentOUs]);

  useEffect(() => {
    if (Array.isArray(fleetTrackingSelection)) {
      setFilteredAssetTypes(JSON.parse(fleetTrackingSelection));
    } else {
      setFilteredAssetTypes(assetTypes.map((row) => row.key));
    }
  }, [assetTypes, fleetTrackingSelection]);

  useEffect(() => {
    const location = entity?.location || entity?.vehicle?.location;

    const postData = async (messageId) => {
      if (!messageId) return;

      const payload = {
        date: createDateTime().toUTC(),
        event: 'Read',
        participant: entity.key,
      };

      apiPost({
        url: `${api.originUrl}/api/messaging/messages/${messageId}/events`,
        body: payload,
        token,
      });
    };

    if (entity && config.SHOW_CONSOLE_LOGS) {
      console.groupCollapsed('FLEET TRACK DATA ENTITY SELECTED INFO');
      console.info(`- entity selected`, entity);
      console.groupEnd();
    }

    if (entity && !isEmpty(location)) {
      map?.setCenter(location);
      map?.setZoom(DEFAULT_UNSELECTED_ZOOM);
    } else {
      map?.setCenter(DEFAULT_LOCATION);
      map?.setZoom(DEFAULT_ZOOM);
    }

    if (entity?.driver?.hasUnreadMessages) {
      postData(entity?.driver?.unreadMessageKey); // this will mark the unread message as read

      const updatedMessage = messages.find(
        (message) => message.key === entity.api.unreadMessageKey
      );

      updatedMessage.events.push({
        date: createDateTime().toUTC(),
        event: 'Read',
        participant: currentSystemUser.key,
      });

      // this will update the unread message to a read state upon entity selection
      setMessages((prevState) =>
        prevState.map((message) => {
          if (message.key === entity.api.unreadMessageKey) {
            return updatedMessage;
          }
          return message;
        })
      );
    }
  }, [entity]);

  /**
   * This useEffect is used to dispatch an action to the EntityContext reducer
   * with a delay of 100ms after the entity has changed. This is necessary because
   * the entity is used in the MarkerCluster component and the useMemo hook
   * is not triggered when the entity changes, so we need to dispatch an action
   * to the reducer to update the state. FilteredDataset dependency is used in the
   * useMemo hook to refresh messenger component content
   */
  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'SET_STORE', payload: { entity, messages, users, setMessages } });
    }, 100);
  }, [entity, filteredDataset]);

  const value = useMemo(
    () => ({
      api,
      assetTypes,
      bookmarks,
      category,
      currentSystemOu,
      currentSystemUser,
      currentUserRoles,
      entity,
      filteredAssetTypes,
      filteredDataset,
      filteredLocations,
      hideInactive,
      locations,
      locationTypes,
      mainDataLoading,
      messages,
      polygons,
      selectedPolygon,
      setBookmarks,
      setCategory,
      setEntity,
      setFilteredAssetTypes,
      setFilteredLocations,
      setFilteredOus,
      setHideInactive,
      setLocations,
      setMessages,
      setSearch,
      setSelectedPolygon,
      token,
      unitSystem,
      users,
    }),
    [
      api,
      assetTypes,
      bookmarks,
      category,
      currentSystemOu,
      currentSystemUser,
      currentUserRoles,
      entity,
      filteredAssetTypes,
      filteredDataset,
      filteredLocations,
      fleetTrackingSelection,
      hideInactive,
      locations,
      locationTypes,
      mainDataLoading,
      messages,
      polygons,
      selectedPolygon,
      setBookmarks,
      setCategory,
      setEntity,
      setFilteredAssetTypes,
      setFilteredLocations,
      setFilteredOus,
      setHideInactive,
      setLocations,
      setMessages,
      setSearch,
      setSelectedPolygon,
      token,
      unitSystem,
      users,
    ]
  );

  return <EntityContext.Provider value={value}>{children}</EntityContext.Provider>;
};

EntityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  values: PropTypes.object,
};

export { EntityProvider, EntityContext };
