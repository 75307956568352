import { isEmpty, isString } from 'lodash';
import { ROLES } from './constants';

const hasRole = (role, type) => {
  const roleToString = isString(role) ? role : role.key;
  return roleToString.includes(type);
};

const findTenantOU = (ous, membershipOuKey) => {
  if (!ous) return null;
  return ous.find(({ key, scope }) => key === membershipOuKey && scope === 'Tenant');
};

export const getRolesByUserMemberships = (user, ous) => {
  if (isEmpty(user)) {
    return [];
  }
  return {
    isDriver: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.DRIVER_ROLE_KEY))
    ),
    isSuperAdmin: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.SUPER_ADMIN_ROLE_KEY))
    ),
    isAdminRole: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.ADMIN_ROLE_KEY))
    ),
    isSystem: user.memberships.some(
      (membership) =>
        membership.role.some((role) => hasRole(role, ROLES.SUPER_ADMIN_ROLE_KEY)) &&
        !isEmpty(findTenantOU(ous, membership.ou))
    ),
    isAdmin: user.memberships.some((membership) =>
      membership.role.some(
        (role) =>
          (hasRole(role, ROLES.SUPER_ADMIN_ROLE_KEY) &&
            isEmpty(findTenantOU(ous, membership.ou))) ||
          hasRole(role, ROLES.ADMIN_ROLE_KEY)
      )
    ),
    isManager: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.MANAGER_ROLE_KEY))
    ),
    isMechanic: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.MECHANIC_ROLE_KEY))
    ),
    isDispatcher: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.DISPATCHER_ROLE_KEY))
    ),
    isGuest: user.memberships.some((membership) =>
      membership.role.some((role) => hasRole(role, ROLES.GUEST_ROLE_KEY))
    ),
  };
};
