import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const TilesContext = createContext();

const TilesProvider = ({ children, values }) => {
  const { state, api, token, currentSystemOu, currentSystemUser } = values;

  const value = useMemo(
    () => ({
      api,
      currentSystemOu,
      currentSystemUser,
      ...state,
      token,
    }),
    [state, api, token, currentSystemOu]
  );

  return <TilesContext.Provider value={value}>{children}</TilesContext.Provider>;
};

TilesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  values: PropTypes.object,
};

export { TilesProvider, TilesContext };
