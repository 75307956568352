import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { uniqueId, lowerCase } from 'lodash';

import { CATEGORIES } from '../utils/constants';

import { TilesContext } from '../context/TilesContext';
import { Camera, Cycle, Hos, Inspection, Order, Shift } from './Tile';

import '../css/Tiles.css';

const Tiles = (props) => {
  const { entity, currentSystemUser } = useContext(TilesContext);
  const { tile, setTile, data } = props;

  const { cameraData, exceptionData, inspectionData, logData, shiftData } = data;

  const { unitSystem } = currentSystemUser || {};

  if (!tile || !entity) return null;

  if (tile === CATEGORIES.SUMMARY) {
    return (
      <div className="Tiles">
        <Hos logData={logData} key={uniqueId('hos')} entity={entity} />
        <Order logData={logData} key={uniqueId('orders')} entity={entity} />
        <Inspection inspectionData={inspectionData} key={uniqueId('inspection')} entity={entity} />
        <Camera
          cameraData={cameraData}
          exceptionData={exceptionData}
          key={uniqueId('camera')}
          setTile={setTile}
          entity={entity}
        />
      </div>
    );
  }

  if (tile === CATEGORIES.DRIVER_LOGS) {
    return (
      <div className="Tiles">
        <Hos logData={logData} key={uniqueId('hos')} entity={entity} />
        <Order logData={logData} key={uniqueId('orders')} entity={entity} />
        <Cycle logData={logData} key={uniqueId('cycle')} entity={entity} />
      </div>
    );
  }

  if (tile === CATEGORIES.INSPECTIONS) {
    return (
      <div className="Tiles">
        <Inspection inspectionData={inspectionData} key={uniqueId('inspection')} entity={entity} />
      </div>
    );
  }

  if (tile === CATEGORIES.CURRENT_SHIFT) {
    return (
      <div className="Tiles">
        <Shift shiftData={shiftData} unitSystem={lowerCase(unitSystem)} entity={entity} />
      </div>
    );
  }

  if (tile === CATEGORIES.CAMERAS) {
    return (
      <div className="Tiles">
        <Camera
          cameraData={cameraData}
          exceptionData={exceptionData}
          isDetailed
          setTile={setTile}
        />
      </div>
    );
  }

  return null;
};

Tiles.propTypes = {
  data: PropTypes.object,
  tile: PropTypes.string,
  setTile: PropTypes.func,
};

export default Tiles;
