import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import upperFirst from 'lodash/upperFirst';

import { EntityContext } from '../context/EntityContext';

import '../css/VehicleTypes.css';
import { STORAGE_KEYS } from '../utils/constants';

const VehicleTypes = ({ assetTypes, onClose }) => {
  const { filteredAssetTypes, setFilteredAssetTypes } = useContext(EntityContext);

  const onTrackNow = () => {
    localStorage.setItem(STORAGE_KEYS.selection, JSON.stringify(filteredAssetTypes));
    setFilteredAssetTypes(filteredAssetTypes);
    onClose();
  };

  const vehicleTypes = assetTypes
    .filter((assetType) => assetType.typeClass === 'Vehicle')
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  const trailerTypes = assetTypes
    .filter((assetType) => assetType.typeClass === 'Trailer')
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));

  return (
    <>
      <div className="VehicleTypes">
        <div className="VehicleTypes__container">
          <h2>Motor Vehicle Types</h2>
          <div className="VehicleTypes__rows">
            {vehicleTypes.map(({ key, name }) => (
              <div className="VehicleTypes__row" key={key}>
                <label htmlFor={`vehicle-${key}`}>{upperFirst(name)}</label>
                <input
                  type="checkbox"
                  id={`vehicle-${key}`}
                  checked={filteredAssetTypes?.includes(key)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilteredAssetTypes([...filteredAssetTypes, key]);
                    } else {
                      setFilteredAssetTypes(
                        filteredAssetTypes?.filter((assetTypeKey) => assetTypeKey !== key)
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="VehicleTypes__container">
          <h2>Trailer Types</h2>
          <div className="VehicleTypes__rows">
            {trailerTypes.map(({ key, name }) => (
              <div className="VehicleTypes__row" key={key}>
                <label htmlFor={`vehicle-${key}`}>{upperFirst(name)}</label>
                <input
                  type="checkbox"
                  id={`vehicle-${key}`}
                  checked={filteredAssetTypes.includes(key)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFilteredAssetTypes([...filteredAssetTypes, key]);
                    } else {
                      setFilteredAssetTypes(
                        filteredAssetTypes.filter((assetTypeKey) => assetTypeKey !== key)
                      );
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="VehicleTypes__selection">
        <span onClick={() => setFilteredAssetTypes(assetTypes.map((row) => row.key))}>
          Select all
        </span>
        <span onClick={() => setFilteredAssetTypes([])}>Remove all</span>
      </div>
      {filteredAssetTypes.length === 0 && (
        <div
          className="VehicleTypes__selection"
          style={{ color: '#FF0000', margin: '10px', fontSize: '14px' }}
        >
          Warning: All tracking selections have been cleared. No markers will be displayed on the
          map without a selection.
        </div>
      )}
      <div className="VehicleTypes__footer">
        <span onClick={() => onClose()}>Cancel</span>
        <span onClick={() => onTrackNow()}>Track Now</span>
      </div>
    </>
  );
};

VehicleTypes.propTypes = {
  assetTypes: PropTypes.array,
  onClose: PropTypes.func,
};

export default VehicleTypes;
