const CAMERA_STATUS = {
  OFFLINE: 'offline',
  ONLINE: 'online',
  STANDBY: 'standBy',
};
const CATEGORIES = {
  CAMERAS: 'cameras',
  CURRENT_SHIFT: 'current shift',
  DRIVER_LOGS: 'driver logs',
  DRIVER: 'driver',
  DRIVERS: 'drivers',
  INSPECTIONS: 'inspections',
  SUMMARY: 'summary',
  TRAILER: 'trailer',
  TRAILERS: 'trailers',
  VEHICLE: 'vehicle',
  VEHICLES: 'vehicles',
};

export const MAP_SETTING_OPTION = [
  {
    label: 'Use radar effect to selected marker',
    value: 'useRadarEffectToSelectedMarker',
  },
  {
    label: 'Group attached trailers markers with the same tractor for trailers category',
    value: 'groupVehiclesAndAttachedTrailersMarkersForTrailerCategory',
  },
  {
    label: 'Cluster nearby vehicles',
    value: 'clusterNearbyVehicles',
  },
  {
    label: 'Show traffic overlay',
    value: 'showTrafficOverlay',
  },
  {
    label: 'Show vehicle labels',
    value: 'showVehicleLabels',
  },
  {
    label: 'Show Search Bar',
    value: 'showSearchBar',
  },
];

const DEFAULT_LOCATION = { lat: 53.5333, lng: -113.4667 };
const DEFAULT_MAP_ID = 'FLEET-TRACK-MAP';
const DEFAULT_UNIT_SYSTEM = 'metric';
const DEFAULT_UNSELECTED_ZOOM = 18;
const DEFAULT_ZOOM = 4;
const DISCONNECTED = 'Disconnected';
const LOADED = 'Loaded';
const NA = 'N/A';
const NO_DRIVER = 'No Driver';
const NO_MESSAGES_FOUND = 'No messages found';
const NO_TRAILERS = 'No Trailers';
const NO_TRAILERS_CONNECTED = 'No Trailers Connected';
const NO_VEHICLE = 'No Vehicle';
const NOT_AVAILABLE = 'Not Available';
const SIDEBAR_TITLE = 'Drivers and Vehicles';
const STORAGE_KEYS = { selection: 'fleetTrackingSelection', settings: 'mapSettingOption' };
const UNKNOWN_TRAILER = 'Unknown Trailer';
const UNLOADED = 'Unloaded';
const VIDEO_EVENT = 'VideoEvent';

const PASTEL_COLORS = [
  { key: 'A', value: '#5bb974' },
  { key: 'B', value: '#4ecde6' },
  { key: 'C', value: '#fcc934' },
  { key: 'D', value: '#af5cf7' },
  { key: 'E', value: '#fa903e' },
  { key: 'F', value: '#ff63b8' },
  { key: 'G', value: '#E0BBE4' },
  { key: 'H', value: '#957DAD' },
  { key: 'I', value: '#D291BC' },
  { key: 'J', value: '#FEC8D8' },
  { key: 'K', value: '#FFDFD3' },
  { key: 'L', value: '#90C978' },
  { key: 'M', value: '#AFD5AA' },
  { key: 'N', value: '#83C6DD' },
  { key: 'O', value: '#5DB1D1' },
  { key: 'P', value: '#58949C' },
  { key: 'Q', value: '#ECE6D2' },
  { key: 'R', value: '#F2D7B4' },
  { key: 'S', value: '#DF9881' },
  { key: 'T', value: '#9A91AC' },
  { key: 'U', value: '#CAA7BD' },
  { key: 'V', value: '#FFB9C4' },
  { key: 'W', value: '#FFD3D4' },
  { key: 'X', value: '#DAE3D9' },
  { key: 'Y', value: '#D4B59D' },
  { key: 'Z', value: '#CE9C6F' },
  { key: '0', value: '#572D15' },
  { key: '1', value: '#B64D3A' },
  { key: '2', value: '#B29DD9' },
  { key: '3', value: '#FE6B64' },
  { key: '4', value: '#77DD77' },
  { key: '5', value: '#779ECB' },
  { key: '6', value: '#26E3E0' },
  { key: '7', value: '#38A6D9' },
  { key: '8', value: '#4076D9' },
  { key: '9', value: '#3854BD' },
];

const DEFAULT_RESOURCE_STATE = {
  loading: false,
  message: null,
  name: null,
  show: false,
  type: null,
  url: null,
};

const ROLES = {
  SUPER_ADMIN_ROLE_KEY: 'S-SUPER_ADMIN',
  ADMIN_ROLE_KEY: 'S-administrator',
  MANAGER_ROLE_KEY: 'S-manager',
  DISPATCHER_ROLE_KEY: 'S-dispatcher',
  MECHANIC_ROLE_KEY: 'S-mechanic',
  DRIVER_ROLE_KEY: 'S-driver',
  GUEST_ROLE_KEY: 'S-guest',
};

export {
  CAMERA_STATUS,
  CATEGORIES,
  DEFAULT_LOCATION,
  DEFAULT_MAP_ID,
  DEFAULT_RESOURCE_STATE,
  DEFAULT_UNIT_SYSTEM,
  DEFAULT_UNSELECTED_ZOOM,
  DEFAULT_ZOOM,
  DISCONNECTED,
  LOADED,
  NA,
  NO_DRIVER,
  NO_MESSAGES_FOUND,
  NO_TRAILERS_CONNECTED,
  NO_TRAILERS,
  NO_VEHICLE,
  NOT_AVAILABLE,
  PASTEL_COLORS,
  ROLES,
  SIDEBAR_TITLE,
  STORAGE_KEYS,
  UNKNOWN_TRAILER,
  UNLOADED,
  VIDEO_EVENT,
};
