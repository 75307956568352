/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import PropTypes from 'prop-types';

import Marker from './Marker';
import MarkerInfo from './MarkerInfo';
import { EntityContext } from '../context/EntityContext';
import { DEFAULT_UNSELECTED_ZOOM, STORAGE_KEYS } from '../utils/constants';

const parser = new DOMParser();

const clusterRenderer = {
  render: ({ count, position, markers }) => {
    const color = count <= 10 ? '#0000ff' : count <= 99 ? '#a35a00' : '#ff0000';

    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
</svg>`;

    const markerSvg = parser.parseFromString(svg, 'image/svg+xml').documentElement;

    const title = markers
      .slice(0, 5)
      .map((m) => m.title)
      .concat(markers.length > 5 ? `+${markers.length - 5} vehicles` : [])
      .join('\n');

    const zIndex = 10000 + count;

    const clusterOptions = {
      position,
      zIndex,
      title,
      content: markerSvg,
    };

    return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
  },
};

export const MarkerCluster = ({ unitSystem }) => {
  const { filteredDataset, entity, setEntity } = useContext(EntityContext);

  const [markers, setMarkers] = useState({});
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const clusterNearbyVehicles = localStorage
    .getItem(STORAGE_KEYS.settings)
    .includes('clusterNearbyVehicles');

  const map = useMap();

  const markerCluster = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      renderer: clusterRenderer,
    });
  }, [map]);

  useEffect(
    () => () => {
      if (markerCluster) {
        markerCluster.clearMarkers();
      }
    },
    [markerCluster]
  );

  /**
   * This useEffect is used to clear the existing markers and add the new markers
   * to the MarkerClusterer whenever the list of markers changes.
   */
  useEffect(() => {
    if (markerCluster && clusterNearbyVehicles) {
      markerCluster.clearMarkers();
      markerCluster.addMarkers(Object.values(markers));
    }

    if (markerCluster && !clusterNearbyVehicles) {
      markerCluster.clearMarkers();
      Object.values(markers).forEach((marker) => marker.setMap(map));
    }
  }, [markers, clusterNearbyVehicles]);

  /**
   * This useEffect is used to set the center of the map to the location of the
   * selected marker when the selected marker key changes.
   */
  useEffect(() => {
    if (!entity) {
      return;
    }

    const selectedRow = filteredDataset.find((row) => row.key === entity?.key);
    const location = selectedRow?.vehicle?.location;

    if (!location) {
      return;
    }

    map.setCenter(location);
    map.setZoom(DEFAULT_UNSELECTED_ZOOM);
  }, [filteredDataset]);

  const setMarkerRef = useCallback(
    (item, key) => {
      setMarkers((items) => {
        if (!items) return {};

        if ((item && items[key]) || (!item && !items[key])) return items;

        if (item) {
          return { ...items, [key]: item };
        }

        const { [key]: _, ...newMarkers } = items || {};

        return newMarkers;
      });
    },
    [filteredDataset]
  );

  const handleClick = useCallback(
    (item) => {
      const isEntitySame = entity?.key === item.key;

      if (!entity || isEntitySame) {
        setEntity(isEntitySame ? null : item);
      } else {
        setEntity(null);
        setTimeout(() => setEntity(item), 100);
      }
    },
    [entity, setEntity]
  );

  const handleMarkerMouseEnter = useCallback((item) => {
    setHoveredMarker(item);
  }, []);

  const handleMarkerMouseLeave = useCallback(() => {
    setHoveredMarker(null);
  }, []);

  return (
    <>
      {filteredDataset
        .filter(({ vehicle }) => vehicle && vehicle?.location)
        .map((row, index) => (
          <Marker
            marker={row}
            index={index}
            key={index}
            onClick={handleClick}
            setMarkerRef={setMarkerRef}
            onMouseEnter={handleMarkerMouseEnter}
            onMouseLeave={handleMarkerMouseLeave}
          />
        ))}
      {hoveredMarker && hoveredMarker?.key && (
        <MarkerInfo
          anchor={markers[hoveredMarker?.key]}
          data={hoveredMarker}
          unitSystem={unitSystem}
        />
      )}
    </>
  );
};

MarkerCluster.propTypes = {
  unitSystem: PropTypes.string,
};
