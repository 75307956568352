import React, { useCallback, useContext } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import PropTypes from 'prop-types';

import { EntityContext } from '../context/EntityContext';
import { getMarker, getMarkerInfo, markerLabelStyle } from '../utils/markers';
import { STORAGE_KEYS } from '../utils/constants';

import '../css/Marker.css';

const Marker = ({ index, marker, onClick, onMouseEnter, onMouseLeave, setMarkerRef }) => {
  const { entity } = useContext(EntityContext);

  const showMarkerLabel = localStorage.getItem(STORAGE_KEYS.settings).includes('showVehicleLabels');

  const handleClick = useCallback(() => onClick(marker), [onClick, marker]);
  const handleOnMouseEnter = useCallback(() => onMouseEnter(marker), [onMouseEnter, marker]);
  const handleOnMouseLeave = useCallback(() => onMouseLeave(marker), [onMouseEnter, marker]);
  const ref = useCallback((item) => setMarkerRef(item, marker.key), [setMarkerRef, marker.key]);

  return (
    <AdvancedMarker
      key={index}
      position={marker?.vehicle?.location}
      ref={ref}
      onClick={handleClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      title={marker?.vehicle?.vehicleId}
    >
      {getMarker(marker, entity?.key)}
      {showMarkerLabel && <div style={markerLabelStyle}>{getMarkerInfo(marker)} </div>}
    </AdvancedMarker>
  );
};

export default Marker;

Marker.propTypes = {
  index: PropTypes.number,
  marker: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  setMarkerRef: PropTypes.func,
};
