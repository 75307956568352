import _ from 'lodash';

import { createDateTime, formatDateTime } from './dates';
import { PASTEL_COLORS } from './constants';

let appThreads = [];
const notificationList = [];

const setMessageSent = (message) => {
  const cloneMsg = _.clone(message);
  if (!cloneMsg.sent) {
    return cloneMsg;
  }
  cloneMsg.sent = _.find(message?.events, { event: 'Sent' });
  return cloneMsg;
};

const setMessageStatus = (message) => {
  const cloneMsg = _.clone(message);
  const recipient = cloneMsg.viewRecipient.reference;
  const recipientEvents = _.filter(cloneMsg.events, { participant: recipient });

  const acknowledged = _.find(recipientEvents, { event: 'Acknowledged' });
  if (acknowledged) {
    cloneMsg.status = `✔ Acknowledged ${formatDateTime(acknowledged.date, 'DDD')}`;
    return cloneMsg;
  }

  const seen = _.find(recipientEvents, { event: 'Read' });
  if (seen) {
    cloneMsg.status = `✔ Seen ${formatDateTime(seen.date, 'DDD')}`;
  }

  return cloneMsg;
};

const setMessageEventSummary = (message) => {
  const cloneMsg = _.clone(message);
  let events;
  const output = [];
  const recipient = cloneMsg.viewRecipient;

  output.push(`Sent by ${cloneMsg.authorName} ${formatDateTime(cloneMsg.date, 'DDD')}`);

  if (recipient._type !== 'Organization') {
    events = _.filter(cloneMsg.events, { participant: recipient.reference });

    _.each(events, function eachEvent(event) {
      if (event.event === 'Read' || event.event === 'Received') {
        output.push(`${event.event} by ${recipient.name} on ${formatDateTime(event.date, 'DDD')}`);
      }
    });
  }

  cloneMsg.eventSummary = output.join('\n');
  return cloneMsg;
};

const enhanceMessages = (messages) =>
  _(messages).map(setMessageSent).map(setMessageStatus).map(setMessageEventSummary).valueOf();

export const getUnreadMessages = (msgs, driverKey) => {
  const enhancedMsgs = enhanceMessages(msgs);

  const recentMessages = _.last(enhancedMsgs.filter((row) => row.author === driverKey));

  const isUnread =
    recentMessages?.events.every((row) => row.event !== 'Read') &&
    recentMessages?.author === driverKey;

  return isUnread;
};

function addThreadMessages(threads, messages) {
  const enhancedMsgs = enhanceMessages(messages);

  enhancedMsgs.forEach((message) => {
    const thread = threads.find((t) => t.threadId === message.threadId) || {
      threadId: message.threadId,
      messages: [],
      ous: [],
    };
    thread.messages = thread.messages.filter((m) => m.key !== message.key).concat(message);
    thread.ous.push(
      ...[
        message.viewRecipient.type === 'Organization' ? message.viewRecipient.reference : null,
        message.viewSender.type === 'Organization' ? message.viewSender.reference : null,
      ]
        .filter(Boolean)
        .filter((ou) => !thread.ous.includes(ou))
    );
    if (!threads.includes(thread)) threads.push(thread);
  });
  return threads;
}

function sortThreadMessages(thread) {
  const cloneThread = _.clone(thread);
  cloneThread.messages = _.sortBy(
    thread.messages,
    (message) => message && message.sent && message.sent.date
  )
    .valueOf()
    .reverse();

  return cloneThread;
}

function determineThreadRecipient(thread) {
  const cloneThread = _.clone(thread);
  const recipient = _.map(cloneThread.messages, 'viewRecipient');
  const sender = _.map(cloneThread.messages, 'viewSender');
  let participants = _.union(recipient, sender);
  participants = _(participants)
    .flatten()
    .reject(_.isUndefined)
    .uniq(false, 'key')
    .filter({ type: 'User' })
    .valueOf();

  [cloneThread.recipient] = participants;

  return cloneThread;
}

function setThreadTitle(thread) {
  const cloneThread = _.clone(thread);
  cloneThread.title = !_.isUndefined(cloneThread.recipient)
    ? cloneThread.recipient.name
    : 'Unknown recipient';
  return cloneThread;
}

function setOriginal(thread) {
  const cloneThread = _.clone(thread);
  if (!_.isUndefined(cloneThread.original)) {
    return cloneThread;
  }

  cloneThread.original = _.find(thread.messages, { key: thread.threadId });
  return cloneThread;
}

function latestThreadMessage(thread) {
  if (thread.messages[0] && thread.messages[0].sent) {
    return createDateTime(thread.messages[0].sent.date).toJSDate();
  }

  return false;
}

const addMessageNotification = (notification) => {
  if (
    notificationList &&
    _.find(notificationList, function findUser(o) {
      if (!o || !o.recipient) return false;
      return o.recipient.reference === notification.recipient.reference;
    }) === undefined
  ) {
    notificationList.push(notification);
  }
};

const updateThreads = (messages) => {
  appThreads = _(appThreads)
    .tap(_.partialRight(addThreadMessages, messages)) // add messages
    .map(sortThreadMessages) // sort thread messages
    .map(determineThreadRecipient) // determine thread recipient
    .map(setThreadTitle) // set thread title
    .map(setOriginal) // shortcut original thread message
    .sortBy(latestThreadMessage) // sort threads
    .valueOf()
    .reverse(); // reverse sort (latest first)

  return appThreads;
};

const groupThreadsByUser = (threads) => {
  const groups = _.groupBy(threads, 'recipient.reference');
  const threadArr = [];
  _.forEach(groups, (item) => {
    const messages = _.orderBy(_.flatten(item.map((trd) => trd.messages)), 'date', 'desc');
    const ous = _.uniq(_.flatten(item.map((trd) => trd.ous)));
    const thread = _.find(threads, (o) => o.threadId === messages[0].threadId);
    const hasBeenRead = _.some(messages[0].events, (o) => o.event === 'Read');

    if (thread.recipient) {
      const conversation = {
        messages,
        ous,
        threadId: messages[0].threadId,
        recipient: thread.recipient,
        title: thread.title,
        hasBeenRead,
      };

      threadArr.push(conversation);
    }
  });
  return threadArr;
};

export const convertToParticipants = (users, activeOu, messages) => {
  if (users.length === 0) {
    return [];
  }

  const threads = updateThreads(messages);
  const threadGroups = groupThreadsByUser(threads);

  return _.sortBy(
    _.map(users, (user) => ({
      key: user.key,
      name: user.fullName,
      reference: user.key,
      homeTerminal: user.homeTerminal || activeOu.name, // fallback to active ou
      type: 'User',
      conversations: _.find(threadGroups, ({ recipient }) => recipient.reference === user.key),
    })),
    ['name']
  );
};

export const getIconColor = (firstCharacter) => {
  if (!firstCharacter) return '#FFFFFF';
  const color = _.find(PASTEL_COLORS, { key: firstCharacter.charAt(0).toUpperCase() });
  return color.value;
};

export const getInitials = (participantName) => {
  if (!participantName) return '';
  const initials = participantName.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};
