import { NA } from './constants';
import { formatDuration as libraryFormatDuration } from './dates';

const formatDistance = (distance, unitSystem = 'metric') => {
  if (!distance) return NA;
  if (unitSystem === 'imperial') {
    return `${(distance * 0.000621371).toFixed(0)} miles`;
  }
  return `${(distance / 1000).toFixed(0)} km`;
};

const formatDuration = (durationInSeconds, format = 'h:mm') => {
  if (!durationInSeconds) return NA;
  return libraryFormatDuration(durationInSeconds, format);
};

const formatSpeed = (speed, unitSystem = 'metric') => {
  if (!speed) return NA;
  if (unitSystem === 'imperial') {
    return `${(speed * 2.23694).toFixed(0)} mph`;
  }
  return `${(speed * 3.6).toFixed(0)} km/h`;
};

export const formatUnit = (unitType, value, formatter) => {
  switch (unitType) {
    case 'distance':
      return formatDistance(value, formatter);
    case 'duration':
      return formatDuration(value, formatter);
    case 'speed':
      return formatSpeed(value);
    default:
      return value;
  }
};

export const convertUnit = (unitType, value, unitSystem) => {
  switch (unitType) {
    case 'distance':
      return unitSystem === 'imperial' ? value * 0.000621371 : value / 1000;
    case 'duration':
      return value / 60;
    case 'speed':
      return unitSystem === 'imperial' ? value * 2.23694 : value * 3.6;
    default:
      return value;
  }
};

export const reverseConvertUnit = (unitType, value, unitSystem) => {
  switch (unitType) {
    case 'distance':
      return unitSystem === 'imperial' ? value / 0.000621371 : value * 1000;
    case 'duration':
      return value * 60;
    case 'speed':
      return unitSystem === 'imperial' ? value / 2.23694 : value / 3.6;
    default:
      return value;
  }
};
