import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { CATEGORIES } from '../../utils/constants';
import { deHumanizeDate } from '../../utils/dates';

import '../../css/Tiles.css';

export const Inspection = ({ inspectionData, entity }) => {
  const { data, isLoading } = inspectionData || {};
  const { assetId, date, inspectorName, locationName, majorDefects, minorDefects, key } =
    data || {};

  return (
    <div className="Tiles__tile">
      <h4>Vehicle Defect Summary</h4>
      <div className="Tiles__tile__content">
        {isLoading ? (
          <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
        ) : (
          <>
            <div className="Tiles__tile__content__item">
              <strong className="duration">{majorDefects}</strong>
              <span>Major Unrepaired</span>
            </div>
            <div className="Tiles__tile__content__item">
              <strong className="duration">{minorDefects}</strong>
              <span>Minor Unrepaired</span>
            </div>
            <div className="Tiles__tile__content__item" style={{ marginTop: '-5px' }}>
              <p>
                {entity?.category === CATEGORIES.VEHICLE
                  ? inspectorName || 'N/A'
                  : assetId || 'N/A'}
              </p>
              <p className="extra">{date && deHumanizeDate(date)}</p>
              <p className="extra">{locationName}</p>
            </div>
          </>
        )}
      </div>
      <div className="Tiles__tile__footer">
        {key ? (
          <a href={`#/reports/inspectionDetail/${key}/default`} target="_blank" rel="noreferrer">
            View Details
          </a>
        ) : (
          <span>No Details</span>
        )}
      </div>
    </div>
  );
};

Inspection.propTypes = {
  inspectionData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
  }),
  entity: PropTypes.object,
};
