import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

import { formatDateTime, formatDuration, createDateTime } from '../../utils/dates';

import '../../css/Tiles.css';

export const Hos = ({ logData, entity }) => {
  const { data, isLoading } = logData || {};
  const { driver, onDutyDrivingRemaining, onDutyRemaining, activeMalfunctions } = data || {};

  const todayDate = formatDateTime(createDateTime().toISO(), 'yyyyMMdd');

  if (!entity?.driver) {
    return null;
  }

  return (
    <div className="Tiles__tile">
      <h4>HOS Summary </h4>
      <div className="Tiles__tile__content">
        {isLoading ? (
          <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
        ) : (
          <>
            <div className="Tiles__tile__content__item">
              <strong>{formatDuration(onDutyDrivingRemaining, 'h:mm')}</strong>
              <span>Driving Remaining</span>
            </div>
            <div className="Tiles__tile__content__item">
              <strong>{formatDuration(onDutyRemaining, 'h:mm')}</strong>
              <span>On Duty Remaining</span>
            </div>
          </>
        )}
      </div>
      <div className="Tiles__tile__footer">
        <a
          href={`#/reports/driverLogSummary/default/driverLogs/${driver?.key}-${todayDate}/default`}
          target="_blank"
          rel="noreferrer"
        >
          View Details
        </a>
        {activeMalfunctions?.length > 0 && (
          <FontAwesomeIcon icon={faCircleExclamation} fontSize={14} />
        )}
      </div>
    </div>
  );
};

Hos.propTypes = {
  logData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
  }),
  entity: PropTypes.object,
};
