import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId, isNil, includes } from 'lodash';
import {
  faLocationDot,
  faArrowUpRightFromSquare,
  faCircleNotch,
  faFolder,
  faFolderOpen,
  faFolderTree,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { formatUnit } from '../../utils/formatters';
import { formatDateTime } from '../../utils/dates';
import { mapActivities } from '../../utils/entities';
import { NOT_AVAILABLE } from '../../utils/constants';
import Modal from '../Modal';

import '../../css/Tiles.css';

const ShiftTableRow = ({
  data,
  unitSystem,
  subActivities,
  index,
  isDetailed,
  setSubActivities,
}) => (
  <div className={`shiftTable__row ${setSubActivities ? '' : 'subActivity'}`}>
    <div>
      {data.subActivities?.length > 0 && setSubActivities ? (
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          icon={subActivities?.index === index ? faFolderOpen : faFolder}
          onClick={() =>
            setSubActivities(
              subActivities?.index === index
                ? { index: null, data: null }
                : { index, data: data.subActivities }
            )
          }
        />
      ) : (
        <span>{subActivities?.index === index ? <FontAwesomeIcon icon={faFolderTree} /> : ''}</span>
      )}
    </div>
    <div>{formatDateTime(data.start, 'h:mm')}</div>
    <div>{data.displayName}</div>
    <div>{formatUnit('duration', data.duration, 'h:mm')}</div>
    <div>{formatUnit('distance', data.distance, unitSystem)}</div>
    <div>{data.assetId}</div>
    <div>{data.notes}</div>
    <div>
      {!isNil(data.startLocation) ? (
        <a
          href={`https://google.com/maps/?q=loc:${data.startLocation?.latitude},${data.startLocation?.longitude}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="location">
            <FontAwesomeIcon icon={faLocationDot} />
            <span>{data.startLocationName}</span>
          </div>
        </a>
      ) : (
        NOT_AVAILABLE
      )}
    </div>

    {isDetailed && (
      <div>
        {!isNil(data.endLocation) ? (
          <a
            href={`https://google.com/maps/?q=loc:${data.endLocation?.latitude},${data.endLocation?.longitude}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="location">
              <FontAwesomeIcon icon={faLocationDot} />
              <span>{data.endLocationName}</span>
            </div>
          </a>
        ) : (
          NOT_AVAILABLE
        )}
      </div>
    )}
  </div>
);

const ShiftTable = ({ shift, isDetailed, unitSystem }) => {
  const [subActivities, setSubActivities] = useState({ index: null, data: null });

  return (
    <div className="shiftTable">
      <div className="shiftTable__header">
        <div />
        <h4>Start Time</h4>
        <h4>Activity Type</h4>
        <h4>Duration</h4>
        <h4>Distance</h4>
        <h4>Vehicle</h4>
        <h4>Notes</h4>
        {!isDetailed && <h4>Location</h4>}
        {isDetailed && <h4>Start Location</h4>}
        {isDetailed && <h4>End Location</h4>}
      </div>

      <div className="shiftTable__wrapper">
        {shift?.activities.map((data, index) => (
          <div key={uniqueId()}>
            <ShiftTableRow
              data={data}
              unitSystem={unitSystem}
              subActivities={subActivities}
              index={index}
              isDetailed={isDetailed}
              setSubActivities={setSubActivities}
              key={uniqueId()}
            />
            {subActivities?.index === index &&
              subActivities.data?.length > 0 &&
              data.subActivities.map((item) => (
                <ShiftTableRow
                  data={item}
                  unitSystem={unitSystem}
                  subActivities={subActivities}
                  index={index}
                  isDetailed={isDetailed}
                  key={uniqueId()}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Shift = ({ shiftData }) => {
  const { data, isLoading } = shiftData || {};
  const { unitSystem } = data || {};

  const [first] = data || [];

  const [openShiftSummaryModal, setOpenShiftSummaryModal] = useState(false);

  const { activities } = first || {};

  if (!first || !activities) {
    return (
      <div className="Tiles__tile Tiles__tile--detailed">
        <h4>Current Shift Details</h4>
        <div className="no-data">
          {isLoading ? (
            <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
          ) : (
            <h4>No current shift details available yet.</h4>
          )}
        </div>
      </div>
    );
  }

  const mapAct = mapActivities(activities);

  const aggregation = {
    duration: mapAct?.reduce((total, activity) => total + activity.duration, 0) || 0,
    distance: mapAct?.reduce((total, activity) => total + activity.distance, 0) || 0,
    loads: mapAct?.filter((activity) => activity.typeClass === 'LoadActivity').length,
    unloads: mapAct?.filter((activity) => activity.typeClass === 'UnloadActivity').length || 0,
    driving:
      mapAct
        ?.filter((activity) => activity.typeClass === 'Driving')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    break:
      mapAct
        ?.filter((activity) => activity.typeClass === 'Break')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    others:
      mapAct
        ?.filter((activity) => activity.typeClass === 'Activity')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    envWait:
      mapAct
        ?.filter((activity) => activity.typeClass === 'EnvironmentalWait')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
    loadUnloadWait: mapAct
      ?.filter((activity) => includes(['LoadWait', 'UnloadWait'], activity.typeClass))
      ?.reduce((total, activity) => total + activity.duration, 0),
    loadUnload: mapAct
      ?.filter((activity) => includes(['Load', 'Unload'], activity.typeClass))
      ?.reduce((total, activity) => total + activity.duration, 0),
    unknown:
      mapAct
        ?.filter((activity) => activity.displayName === 'Unknown Stop')
        ?.reduce((total, activity) => total + activity.duration, 0) || 0,
  };

  return (
    <div className="Tiles__tile Tiles__tile--detailed">
      <div className="shift__wrapper">
        <FontAwesomeIcon
          icon={isLoading ? faCircleNotch : faArrowUpRightFromSquare}
          fontSize={14}
          onClick={() => (isLoading ? null : setOpenShiftSummaryModal(true))}
        />
        <h4>Current Shift Details</h4>
      </div>

      {isLoading ? (
        <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
      ) : (
        <ShiftTable isDetailed={false} shift={first} unitSystem={unitSystem} />
      )}
      <Modal
        isOpen={openShiftSummaryModal}
        onClose={() => setOpenShiftSummaryModal(false)}
        title={
          <>
            <FontAwesomeIcon icon={faList} fontSize={14} />
            <span>Shift Summary</span>
          </>
        }
      >
        <div className="shiftSummary__modal__row">
          <div className="container">
            <div className="box">
              <h4>Start Time</h4>
              <p>{formatDateTime(first?.start, 'HH:mm')} </p>
            </div>
            <div className="box">
              <h4>End Time</h4>
              <p>{formatDateTime(first?.end, 'HH:mm')} </p>
            </div>
            <div className="box">
              <h4>Shift Length</h4>
              <p>
                {aggregation.duration > 0 ? formatUnit('duration', aggregation.duration) : '--:--'}
              </p>
            </div>
            <div className="box">
              <h4>Driving Distance</h4>
              <p>
                {aggregation.distance > 0
                  ? formatUnit('distance', aggregation.distance, unitSystem)
                  : '--:--'}
              </p>
            </div>
            <div className="box">
              <h4>Loads/Unloads</h4>
              <p>{`${aggregation.loads} / ${aggregation.unloads}`} </p>
            </div>
          </div>
        </div>
        <div className="shiftSummary__modal__row">
          <h4>Time Summary</h4>
          <div className="container">
            <div className="box">
              <h4>Driving</h4>
              <p>{formatUnit('duration', aggregation.driving)} </p>
            </div>
            <div className="box">
              <h4>Break</h4>
              <p>{formatUnit('duration', aggregation.break)} </p>
            </div>
            <div className="box">
              <h4>Other Activities</h4>
              <p>{formatUnit('duration', aggregation.others)} </p>
            </div>
            <div className="box">
              <h4>Env. Wait</h4>
              <p>{formatUnit('duration', aggregation.envWait)} </p>
            </div>
            <div className="box">
              <h4>Waiting to Load/Unload</h4>
              <p>{formatUnit('duration', aggregation.loadUnloadWait)} </p>
            </div>
            <div className="box">
              <h4>Load/Unload</h4>
              <p>{formatUnit('duration', aggregation.loadUnload)} </p>
            </div>
            <div className="box">
              <h4>Unknown</h4>
              <p>{formatUnit('duration', aggregation.unknown)} </p>
            </div>
          </div>
        </div>
        <div className="shiftSummary__modal__row">
          <h4>Shift Timeline</h4>
          <div className="container">
            <ShiftTable isDetailed shift={first} unitSystem={unitSystem} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

Shift.propTypes = {
  shiftData: PropTypes.shape({
    data: PropTypes.any,
    isLoading: PropTypes.bool,
  }),
};

ShiftTable.propTypes = {
  isDetailed: PropTypes.bool,
  shift: PropTypes.object,
  unitSystem: PropTypes.string,
};

ShiftTableRow.propTypes = {
  data: PropTypes.object,
  unitSystem: PropTypes.string,
  subActivities: PropTypes.object,
  index: PropTypes.number,
  isDetailed: PropTypes.bool,
  setSubActivities: PropTypes.func,
};
