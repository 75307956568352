import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faCamera, faXmark, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { includes, startCase, uniqueId } from 'lodash';

import { formatDateTime } from '../../utils/dates';
import {
  closeResourceWindow,
  getCameraData,
  getRecordedFiles,
  getSnapshot,
  getVideo,
} from '../../utils/camera';
import { CATEGORIES, DEFAULT_RESOURCE_STATE, VIDEO_EVENT } from '../../utils/constants';
import { TilesContext } from '../../context/TilesContext';

import '../../css/Tiles.css';

const MediaContainer = ({ playerHeight, resource, setResource, entity }) => (
  <div
    className="resourceContainer"
    style={{
      display: resource?.show ? 'block' : 'none',
      width: playerHeight + 240,
      height: playerHeight + 85,
      bottom: 290,
    }}
  >
    <div className="resourceContainer__header">
      <h4>
        {resource?.name} - {entity?.vehicle?.vehicleId} - {formatDateTime(null, 'h:mm')} -{' '}
        {resource?.type === 'LIVESTREAM-VIDEO' ? 'Live' : 'Recorded'}
      </h4>
      <FontAwesomeIcon
        icon={faXmark}
        onClick={() => closeResourceWindow(setResource, DEFAULT_RESOURCE_STATE)}
      />
    </div>

    {includes(resource?.type, 'VIDEO') && (
      <video
        id="videoPlayer"
        autoPlay
        controls
        style={{
          display: resource?.show ? 'block' : 'none',
          height: playerHeight + 10,
          padding: 10,
        }}
        width="96%"
        {...(resource?.url && { src: resource?.url })}
      >
        <track kind="captions" />
      </video>
    )}

    {includes(resource?.type, 'SNAPSHOT') && (
      <div
        style={{
          display: resource?.show ? 'block' : 'none',
          height: playerHeight + 10,
          padding: 10,
        }}
      >
        <img
          style={{
            display: resource?.url ? 'block' : 'none',
            height: playerHeight + 10,
            padding: 10,
          }}
          width="96%"
          src={resource?.url}
          alt="snapshot"
        />
      </div>
    )}

    {resource?.message && <div className="resourceContainer__footer">{resource?.message}</div>}
  </div>
);

export const Camera = ({ isDetailed, cameraData, exceptionData, setTile }) => {
  const { entity, api, token } = useContext(TilesContext);
  const [resource, setResource] = useState(null);

  const vehicleKey = entity?.vehicle?.vehicle;

  const cameraEvents = exceptionData?.data
    ?.filter((item) => item.exceptionType === VIDEO_EVENT)
    ?.map((item) => ({
      date: formatDateTime(item.startedAt, 'MMM D, YYYY HH:mm'),
      hour: formatDateTime(item.startedAt, 'HH:mm'),
      type: startCase(item.other?.typeDescription),
      fileLocations: item.other?.fileLocations,
    }));

  useEffect(
    () => () => {
      setResource(DEFAULT_RESOURCE_STATE);
    },
    []
  );

  const playerHeight = window.innerHeight > 780 ? 360 : 260;

  if (!entity?.vehicle?.camera?.provider) {
    return null;
  }

  if (isDetailed) {
    return (
      <div className="Tiles__tile Tiles__tile--detailed">
        <div className="cameraDetail">
          <div className="cameraDetail__table">
            <h4>Camera - Live</h4>
            <div className="cameraDetail__table__row">
              <div className="cameraIcon">
                <FontAwesomeIcon icon={faVideo} /> <span>Video</span>
              </div>
              <div className="cameraLinks">
                {cameraData?.data?.map((row) => (
                  <button
                    type="button"
                    onClick={() =>
                      getVideo({ row, api, token, setResource, DEFAULT_RESOURCE_STATE })
                    }
                    key={row.id}
                  >
                    {row.name}
                  </button>
                ))}
              </div>
            </div>
            <div className="cameraDetail__table__row">
              <div className="cameraIcon">
                <FontAwesomeIcon icon={faCamera} /> <span>Snapshot</span>
              </div>
              <div className="cameraLinks">
                {cameraData?.data?.map((row) => (
                  <button
                    type="button"
                    onClick={() =>
                      getSnapshot({ row, api, token, setResource, DEFAULT_RESOURCE_STATE })
                    }
                    key={row.id}
                  >
                    {row.name}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="cameraDetail__table">
            <div className="cameraDetail__table__header">
              <h4>Camera Events - Today </h4>
              <h4>Time </h4>
              <h4>Source </h4>
            </div>

            <div className="cameraDetail__table__wrapper">
              {cameraEvents?.map((event, index) => (
                <div className="cameraDetail__table__row" key={index}>
                  <span>{event.type}</span>
                  <span>{event.hour}</span>
                  <div className="cameraLinks">
                    {event.fileLocations.map((fileLocation) => (
                      <div className="cameraIcon" key={uniqueId()}>
                        <FontAwesomeIcon
                          icon={fileLocation.fileType === 'snapshot' ? faCamera : faVideo}
                        />
                        <button
                          type="button"
                          onClick={() =>
                            getRecordedFiles({ fileLocation, api, token, setResource })
                          }
                          key={uniqueId()}
                        >
                          {fileLocation.cameraName}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <MediaContainer
          playerHeight={playerHeight}
          resource={resource}
          setResource={setResource}
          entity={entity}
        />
      </div>
    );
  }

  return (
    <div className="Tiles__tile">
      <h4>Camera - Live </h4>
      <div className="Tiles__tile__content">
        <div className="Tiles__tile__content__item">
          <div className="cameraContainer">
            <FontAwesomeIcon icon={faVideo} fontSize={12} /> <span>Video</span>
          </div>
          <div className="cameraContainer">
            <FontAwesomeIcon icon={faCamera} fontSize={12} /> <span>Snapshot</span>
          </div>
        </div>
        <div className="Tiles__tile__content__item">
          {cameraData?.isLoading ? (
            <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} fontSize={12} />
          ) : (
            cameraData?.data?.map((row) => (
              <div className="cameraContainer" key={row.name}>
                <button
                  type="button"
                  onClick={() => getVideo({ row, api, token, setResource, DEFAULT_RESOURCE_STATE })}
                >
                  {row.name}
                </button>
                <button
                  type="button"
                  onClick={() =>
                    getSnapshot({ row, api, token, setResource, DEFAULT_RESOURCE_STATE })
                  }
                >
                  {row.name}
                </button>
              </div>
            ))
          )}
          {cameraData?.isLoading === false && cameraData?.data?.length === 0 && (
            <span>No camera data found</span>
          )}
        </div>
      </div>
      <div className="Tiles__tile__footer">
        <button type="button" onClick={() => setTile(CATEGORIES.CAMERAS)}>
          View Details
        </button>
        {!cameraData?.data ||
          (cameraData?.data?.length === 0 && vehicleKey && (
            <button
              type="button"
              onClick={() =>
                getCameraData({ vehicleKey, api, token, resource, setResource, setTile })
              }
            >
              Reload Data
            </button>
          ))}
      </div>
      <MediaContainer
        playerHeight={playerHeight}
        resource={resource}
        setResource={setResource}
        entity={entity}
      />
    </div>
  );
};

Camera.propTypes = {
  isDetailed: PropTypes.bool,
  cameraData: PropTypes.shape({
    data: PropTypes.array,
    isLoading: PropTypes.bool,
  }),
  setTile: PropTypes.func,
  exceptionData: PropTypes.shape({
    data: PropTypes.array,
    isLoading: PropTypes.bool,
  }),
};

MediaContainer.propTypes = {
  playerHeight: PropTypes.number,
  resource: PropTypes.object,
  setResource: PropTypes.func,
  entity: PropTypes.object,
};
