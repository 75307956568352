import React from 'react';
import PropTypes from 'prop-types';

import '../css/Tooltip.css';

const Tooltip = ({ children, text }) => (
  <div className="tooltip">
    {children}
    <span className="tooltip-text">{text}</span>
  </div>
);

Tooltip.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
};

export default Tooltip;
