import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  faAngleLeft,
  faAngleRight,
  faTruck,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faEnvelope,
  faUser,
  faVideo,
  faCircleInfo,
  faSliders,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import upperFirst from 'lodash/upperFirst';

import { CAMERA_STATUS, CATEGORIES, SIDEBAR_TITLE } from '../utils/constants';
import { EntityContext } from '../context/EntityContext';

import Modal from './Modal';
import Tooltip from './Tooltip';
import VehicleTypes from './VehicleTypes';

import '../css/SideBar.css';

const setIcon = (category, row) => {
  switch (category) {
    case CATEGORIES.DRIVER: {
      if (row?.driver?.hasUnreadMessages) {
        return <FontAwesomeIcon icon={faEnvelope} fontSize={14} />;
      }
      if (row?.vehicle) {
        return <FontAwesomeIcon icon={faTruck} fontSize={14} style={{ transform: 'scaleX(-1)' }} />;
      }
      return <FontAwesomeIcon icon={faUser} fontSize={14} />;
    }
    case CATEGORIES.VEHICLE:
      return <FontAwesomeIcon icon={faTruck} fontSize={14} style={{ transform: 'scaleX(-1)' }} />;
    case CATEGORIES.TRAILERS:
      return <FontAwesomeIcon icon={faTruck} fontSize={14} style={{ transform: 'scaleX(-1)' }} />;
    default:
      return <FontAwesomeIcon icon={faQuestion} fontSize={14} />;
  }
};

const setCameraIconColor = (camera) => {
  if (camera?.status === CAMERA_STATUS.OFFLINE) {
    return { color: '#888888' };
  }
  if (camera?.status === CAMERA_STATUS.STANDBY) {
    return { color: '#333333' };
  }
  return { color: '#30AA0C' };
};

const getSideBarRowStyle = (item, key) => {
  if (item.key === key) {
    return 'isSelected';
  }
  if (item.driver?.hasUnreadMessages) {
    return 'hasUnreadMessages';
  }

  return null;
};

const SideBar = ({ ous }) => {
  const {
    assetTypes,
    category,
    entity,
    filteredDataset,
    hideInactive,
    mainDataLoading,
    setCategory,
    setEntity,
    setFilteredOus,
    setHideInactive,
    setSearch,
  } = useContext(EntityContext);

  const [collapse, setCollapse] = useState(false);
  const [enableFilter, setEnableFilter] = useState(false);
  const [isOrgBoxOpen, setIsOrgBoxOpen] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [ouSearch, setOuSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const selectedRef = useRef(null);

  const currentOUs = useMemo(() => ous.map(({ key }) => key), [ous]);

  useEffect(() => {
    if (!enableFilter) {
      setSelectedOrg(null);
    }
  }, [enableFilter]);

  useEffect(() => {
    if (!enableFilter) {
      setFilteredOus(currentOUs);
    } else if (selectedOrg) {
      setFilteredOus([selectedOrg]);
    }
  }, [enableFilter, selectedOrg]);

  useEffect(() => {
    if (entity) {
      selectedRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [entity]);

  const clearSelectedEntity = () => {
    if (entity) {
      setEntity(null);
    }
  };

  if (collapse) {
    return (
      <div className="SideBar-collapsed">
        <div className="SideBar__header" onClick={() => setCollapse(!collapse)}>
          <FontAwesomeIcon icon={faTruck} fontSize={14} style={{ transform: 'scaleX(-1)' }} />
          <FontAwesomeIcon icon={faAngleRight} fontSize={14} />
        </div>
      </div>
    );
  }

  return (
    <div className="SideBar">
      <div className="SideBar__header" onClick={() => setCollapse(!collapse)}>
        <h2 className="SideBar__header-title">{SIDEBAR_TITLE}</h2>
        <FontAwesomeIcon icon={faAngleLeft} fontSize={14} />
      </div>
      <div className="SideBar__categories">
        <button
          type="button"
          disabled={category === CATEGORIES.DRIVER}
          onClick={() => {
            setCategory(CATEGORIES.DRIVER);
            clearSelectedEntity();
          }}
        >
          {upperFirst(CATEGORIES.DRIVERS)}
        </button>
        <button
          type="button"
          disabled={category === CATEGORIES.VEHICLE}
          onClick={() => {
            setCategory(CATEGORIES.VEHICLE);
            clearSelectedEntity();
          }}
        >
          {upperFirst(CATEGORIES.VEHICLES)}
        </button>
        <button
          type="button"
          disabled={category === CATEGORIES.TRAILERS}
          onClick={() => {
            setCategory(CATEGORIES.TRAILERS);
            clearSelectedEntity();
          }}
        >
          {upperFirst(CATEGORIES.TRAILERS)}
        </button>
      </div>
      <div className="SideBar__organizations">
        <div className="filter">
          <input
            type="checkbox"
            checked={enableFilter}
            onChange={(e) => setEnableFilter(e.target.checked)}
          />
          <span>Filter By Organization</span>
        </div>
        <div
          className="orgs"
          onClick={() => (enableFilter ? setIsOrgBoxOpen(!isOrgBoxOpen) : null)}
        >
          <span>{ous.find((item) => item.key === selectedOrg)?.name || 'Organizations'}</span>
          <FontAwesomeIcon icon={isOrgBoxOpen ? faCaretUp : faCaretDown} fontSize={11} />
        </div>
        <div
          className="org-search"
          style={isOrgBoxOpen && enableFilter ? { display: 'block' } : { display: 'none' }}
        >
          <div className="search-input">
            <input type="text" onChange={(e) => setOuSearch(e.target.value)} />
            <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={14} />
          </div>
          <div className="org-list">
            {ous
              .filter((item) => item.name.toLowerCase().includes(ouSearch.toLowerCase()))
              .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
              .map((item) => (
                <div
                  key={item.key}
                  style={selectedOrg === item.key ? { backgroundColor: '#eeeeee' } : null}
                  className="org-list-item"
                  onClick={() => {
                    setSelectedOrg(item.key);
                    setIsOrgBoxOpen(false);
                    setFilteredOus([item.key]);
                  }}
                >
                  {item.name}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="SideBar__search">
        <div className="search-input">
          <input type="text" onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
          <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={14} />
        </div>
      </div>
      <div className="SideBar__rows">
        {mainDataLoading && <div>Loading...</div>}
        {filteredDataset.map((item) => (
          <div
            ref={entity?.key === item.key ? selectedRef : null}
            key={item.key}
            className={`SideBar__row ${getSideBarRowStyle(item, entity?.key)}`}
            onClick={() => {
              const isEntitySame = entity?.key === item.key;

              if (!entity || isEntitySame) {
                setEntity(isEntitySame ? null : item);
              } else {
                setEntity(null);
                setTimeout(() => setEntity(item), 100);
              }
            }}
          >
            <div>{setIcon(category, item)}</div>
            <div>
              <p className="SideBar__row__name">{item.title}</p>
              <p className="SideBar__row__extra">{item.extra}</p>
            </div>
            <div className="SideBar__row__info">
              <div>
                {item.vehicle?.camera?.provider && (
                  <FontAwesomeIcon
                    icon={faVideo}
                    style={setCameraIconColor(item.vehicle?.camera)}
                    fontSize={14}
                  />
                )}
              </div>
              <div>{item.lastSeen}</div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: item.statusColor,
                    marginTop: 2,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {!filteredDataset.length && !mainDataLoading && <div>No results found</div>}
      </div>
      <div className="SideBar__inactive">
        <input
          type="checkbox"
          checked={hideInactive}
          onChange={() => setHideInactive(!hideInactive)}
        />
        <span>Hide Inactive</span>
        <Tooltip text="Only vehicles with known location will be shown">
          <FontAwesomeIcon icon={faCircleInfo} fontSize={16} />
        </Tooltip>
      </div>
      <div className="SideBar__trackingSelection">
        <FontAwesomeIcon icon={faSliders} fontSize={14} />
        <span onClick={() => setIsOpen(true)}>Change tracking selection</span>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <>
            <FontAwesomeIcon icon={faSliders} fontSize={14} />
            <span>Tracking Selection</span>
          </>
        }
      >
        <VehicleTypes assetTypes={assetTypes} onClose={() => setIsOpen(false)} />
      </Modal>
    </div>
  );
};

SideBar.propTypes = {
  ous: PropTypes.array,
};

export default SideBar;
