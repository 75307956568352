/* eslint-disable no-undef */

import React, { useEffect, useState, useContext } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

import {
  faPlus,
  faEdit,
  faUndo,
  faTrash,
  faSave,
  faCaretUp,
  faCaretDown,
  faMagnifyingGlass,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isNil from 'lodash/isNil';

import { EntityContext } from '../context/EntityContext';
import { convertUnit, reverseConvertUnit } from '../utils/formatters';
import { apiPost, apiPut, apiDelete } from '../utils/api';

import Modal from './Modal';

import '../css/Geozones.css';

function validateMatrix(matrix) {
  return matrix.every((row) => row.length === 2 && row.every(Number.isFinite));
}

const drawingInstance = (drawing, map, setDrawMode, setSelectedPolygon) => {
  const newDrawingManager = new drawing.DrawingManager({
    map,
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
    drawingControl: false,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [google.maps.drawing.OverlayType.POLYGON],
    },
    polygonOptions: {
      editable: true,
      draggable: true,
    },
  });

  setDrawMode('CREATE');

  const onPolygonComplete = (newPolygon) => {
    const path = newPolygon.getPath();
    const coordinates = [];
    path.forEach((latLng) => {
      coordinates.push([latLng.lat(), latLng.lng()]);
    });

    // Check if the polygon has at least 3 coordinates, if not return to CREATE mode making the same behaviour as angularJS version
    if (coordinates.length < 3) {
      newPolygon.setMap(null);
      setSelectedPolygon(null);
      setDrawMode('CREATE');
      return;
    }

    newPolygon.setOptions({
      fillColor: '#FF0000',
      strokeColor: '#000000',
      strokeOpacity: 0.5,
      strokeWeight: 1,
    });
    newPolygon.setEditable(false);
    setSelectedPolygon(newPolygon);
    setDrawMode('COMPLETED');
    newDrawingManager.setDrawingMode(null);
  };

  google.maps.event.clearListeners(newDrawingManager, 'polygoncomplete');
  google.maps.event.addListener(newDrawingManager, 'polygoncomplete', onPolygonComplete);

  return newDrawingManager;
};

const getCoordinates = (polygon) => {
  if (!polygon) return [];

  if (typeof polygon.getPath !== 'function') return polygon?.data.locations;

  const path = polygon.getPath();
  const coordinates = [];
  path.forEach((latLng) => {
    coordinates.push([latLng.lat(), latLng.lng()]);
  });

  return coordinates;
};

const onChangeValidation = ({ target: { name, value } }, formState, setFormState) => {
  setFormState({
    ...formState,
    [name]: value ? '' : `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`,
  });
};

const onCrossValidation = (speed, gracePeriod) => {
  if (speed && !gracePeriod) {
    return {
      speed: '',
      gracePeriod: 'If speed is set, Grace period is required.',
    };
  }

  if (gracePeriod && !speed) {
    return {
      speed: 'If grace period is set, Speed is required.',
      gracePeriod: '',
    };
  }

  return {
    gracePeriod: '',
    speed: '',
  };
};

const calculateAreaSizeFromLatLngList = (coords) => {
  const R = 6371; // Radius of the Earth in kilometers
  let area = 0;

  for (let i = 0; i < coords.length; i += 1) {
    const lat1 = (coords[i][0] * Math.PI) / 180;
    const lon1 = (coords[i][1] * Math.PI) / 180;
    const lat2 = (coords[(i + 1) % coords.length][0] * Math.PI) / 180;
    const lon2 = (coords[(i + 1) % coords.length][1] * Math.PI) / 180;

    area += (lon2 - lon1) * (2 + Math.sin(lat1) + Math.sin(lat2));
  }

  area = (area * R * R) / 2;
  return Math.abs(area);
};

const handleSubmit = async ({
  api,
  event,
  formState,
  locationTypes,
  polygon,
  polygonCoordinates,
  setApiLoading,
  token,
  unitSystem,
}) => {
  event.preventDefault();

  const formData = new FormData(event.target);
  const jsonData = Object.fromEntries(formData.entries());

  const { name, type, color, speed, gracePeriod } = jsonData;

  const speedLimitCondition = (!speed && gracePeriod) || (speed && !gracePeriod);
  const coordinates = polygonCoordinates || getCoordinates(polygon);

  const isValidMatrix = validateMatrix(coordinates);

  const isNilOrEmpty = (value) => isNil(value) || value === '';

  if (
    isNilOrEmpty(name) ||
    isNilOrEmpty(type) ||
    isNilOrEmpty(color) ||
    speedLimitCondition ||
    !isValidMatrix
  ) {
    throw {
      error: new Error('Form is not valid'),
      state: {
        ...formState,
        name: name === '' ? 'Name is required.' : '',
        type: isNilOrEmpty(type) ? 'Type is required.' : '',
        color: color === '' ? 'Color is required.' : '',
        ...onCrossValidation(speed, gracePeriod),
        coordinates: isValidMatrix ? '' : 'Coordinate is required.',
      },
    };
  }

  const locationType = locationTypes.find((lt) => lt.key === jsonData.type);
  const data = polygon?.data;

  const calcSpeed = jsonData.speed
    ? reverseConvertUnit('speed', Math.max(0, jsonData.speed), unitSystem)
    : data?.speedLimit?.speed;

  const speedLimit =
    speed || jsonData?.gracePeriod
      ? { limit: Number(calcSpeed), gracePeriod: Number(jsonData.gracePeriod) }
      : null;

  const action = data ? 'edit' : 'create';

  const payload = {
    ...(action === 'edit' && {
      ...data,
      name,
      type: locationType?.key,
      typeName: locationType?.name,
      color,
      locations: getCoordinates(polygon)?.map(([lat, lng]) => ({ latitude: lat, longitude: lng })),
      latLngList: getCoordinates(polygon)?.map(([lat, lng]) => ({ lat, lng })),
      areaSize: calculateAreaSizeFromLatLngList(getCoordinates(polygon)),
      speedLimit,
    }),
    ...(action === 'create' && {
      name,
      type: locationType?.key,
      color,
      locations: getCoordinates(polygon)?.map(([lat, lng]) => ({ latitude: lat, longitude: lng })),
      ...(speedLimit && { speedLimit }),
    }),
  };

  setApiLoading(true);
  if (action === 'edit') {
    const url = `${api.originUrl}/api/admin/locations/${polygon.data?.key}`;
    const response = await apiPut({ url, token, body: payload });

    return response;
  }

  const url = `${api.originUrl}/api/admin/locations`;
  const response = await apiPost({ url, token, body: payload });

  return response;
};

const closeModal = (setIsOpen, setDrawMode, selectedPolygon, setSelectedPolygon, setFormState) => {
  setIsOpen(false);
  setDrawMode('STANDBY');
  if (typeof selectedPolygon?.setEditable === 'function') {
    selectedPolygon.setEditable(false);
  }

  if (!selectedPolygon?.data?.key && typeof selectedPolygon?.setMap === 'function') {
    selectedPolygon.setMap(null);
  }

  setTimeout(() => {
    setFormState({ name: '', type: '', color: '' });
    setSelectedPolygon(null);
  }, 100);
};

const resetTypeList = ({ locationTypes, setTypeList, setTypeSearch, setIsTypeBoxOpen }) => {
  setTypeSearch('');
  setTypeList(locationTypes);
  setIsTypeBoxOpen(false);
};

export const Geozones = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [displayColor, setDisplayColor] = useState('#000000');
  const [drawInstance, setDrawInstance] = useState(null);
  const [drawMode, setDrawMode] = useState('STANDBY');
  const [formState, setFormState] = useState({ name: '', type: '', color: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [isTypeBoxOpen, setIsTypeBoxOpen] = useState(false);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [showCoordinates, setShowCoordinates] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [typeList, setTypeList] = useState([]);
  const [typeSearch, setTypeSearch] = useState('');

  const drawing = useMapsLibrary('drawing');
  const map = useMap();

  const {
    api,
    currentUserRoles,
    locations,
    locationTypes,
    selectedPolygon,
    setLocations,
    setSelectedPolygon,
    token,
    unitSystem,
  } = useContext(EntityContext);

  useEffect(() => {
    if (selectedPolygon?.data) {
      setSelectedType(selectedPolygon?.data?.type);
      setDisplayColor(selectedPolygon?.data?.color);
      setDrawMode('EDIT');
    }
    setPolygonCoordinates(getCoordinates(selectedPolygon));
  }, [selectedPolygon]);

  useEffect(() => {
    if (drawMode === 'COMPLETED') {
      setIsOpen(true);
    }
  }, [drawMode]);

  useEffect(() => {
    setTypeList(locationTypes);
  }, [locationTypes]);

  return (
    <div className="geozones">
      {drawMode === 'STANDBY' && currentUserRoles?.isSuperAdmin && (
        <button
          className="button"
          type="button"
          onClick={() =>
            setDrawInstance(drawingInstance(drawing, map, setDrawMode, setSelectedPolygon))
          }
          disabled={drawMode !== 'STANDBY'}
        >
          + New Geozone
        </button>
      )}

      {drawMode === 'CREATE' && currentUserRoles?.isSuperAdmin && (
        <>
          <button className="button" type="button" disabled>
            Draw a Geozone
          </button>
          <button
            type="button"
            aria-label="undo"
            onClick={() => {
              setDrawMode('STANDBY');
              selectedPolygon?.setMap(null);
              drawInstance.setMap(null);
            }}
            className="button"
            style={{ minWidth: 40 }}
          >
            <FontAwesomeIcon icon={faUndo} fontSize={16} />
          </button>
        </>
      )}

      {drawMode === 'EDIT' && currentUserRoles?.isSuperAdmin && (
        <>
          <button
            aria-label={selectedPolygon?.data?.name}
            className="button"
            type="button"
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => {
              const buttonGroup = document.querySelector('.button-group');
              buttonGroup?.addEventListener('mouseleave', () => setShowMenu(false));
            }}
          >
            {selectedPolygon?.data?.name}
          </button>
          <div className="button-group" style={{ display: showMenu ? 'grid' : 'none' }}>
            <button type="button" onClick={() => selectedPolygon?.setEditable(true)}>
              <FontAwesomeIcon icon={faEdit} fontSize={12} /> <span>Edit Shape</span>
            </button>
            <button
              type="button"
              onClick={() => {
                selectedPolygon?.setEditable(false);
                setDrawMode('COMPLETED');
              }}
            >
              <FontAwesomeIcon icon={faEdit} fontSize={12} /> <span>Edit Properties</span>
            </button>
            <button
              type="button"
              onClick={() => {
                const url = `${api.originUrl}/api/admin/locations/${selectedPolygon?.data?.key}`;
                selectedPolygon?.setMap(null);
                apiDelete({ url, token })
                  .then(() =>
                    setLocations(locations.filter(({ key }) => key !== selectedPolygon?.data?.key))
                  )
                  .catch((error) => console.error(error));
                setDrawMode('STANDBY');
              }}
            >
              <FontAwesomeIcon icon={faTrash} fontSize={12} /> <span>Delete</span>
            </button>
          </div>
          <button
            className="button"
            type="button"
            aria-label="Undo"
            onClick={() => {
              setDrawMode('STANDBY');
              selectedPolygon?.setEditable(false);

              // upon cancel we need to revert the polygon to its original coordinates in case we edit and not save it
              const path = new google.maps.MVCArray(
                polygonCoordinates.map((point) => {
                  return new google.maps.LatLng(point[0], point[1]);
                })
              );
              selectedPolygon?.setPath(path);

              // finally we need to reset the selected polygon state to null
              setSelectedPolygon(null);
            }}
            style={{ minWidth: 40 }}
          >
            <FontAwesomeIcon icon={faUndo} fontSize={16} />
            <span>Cancel</span>
          </button>
          {selectedPolygon?.editable && (
            <button
              className="button"
              type="button"
              aria-label="Save"
              onClick={() => {
                const path = selectedPolygon?.getPath();
                const coordinates = [];
                path.forEach((latLng) => coordinates.push([latLng.lat(), latLng.lng()]));
                selectedPolygon.setEditable(false);
                setDrawMode('COMPLETED');
              }}
            >
              <FontAwesomeIcon icon={faSave} fontSize={16} />
              <span>Save</span>
            </button>
          )}
        </>
      )}

      <Modal
        isOpen={isOpen}
        onClose={() => {
          resetTypeList({ locationTypes, setTypeList, setTypeSearch, setIsTypeBoxOpen });
          closeModal(setIsOpen, setDrawMode, selectedPolygon, setSelectedPolygon, setFormState);
        }}
        title={
          <>
            <FontAwesomeIcon icon={selectedPolygon?.data ? faEdit : faPlus} fontSize={14} />
            <span>
              Geozone {selectedPolygon?.data?.name ? ` : ${selectedPolygon?.data?.name}` : ''}
            </span>
          </>
        }
      >
        <form
          noValidate
          className="form-geozone"
          onSubmit={(e) =>
            handleSubmit({
              api,
              event: e,
              formState,
              locationTypes,
              polygon: selectedPolygon,
              polygonCoordinates,
              setApiLoading,
              token,
              unitSystem,
            })
              .then((response) => {
                const isNew = selectedPolygon?.data?.key === undefined;

                if (isNew) {
                  setLocations([...locations, response]);
                } else {
                  const index = locations.findIndex((location) => location.key === response?.key);

                  if (index === -1) return;
                  const newLocations = [...locations];
                  newLocations[index] = response;
                  setLocations(newLocations);
                }

                resetTypeList({ locationTypes, setTypeList, setTypeSearch, setIsTypeBoxOpen });
                closeModal(
                  setIsOpen,
                  setDrawMode,
                  selectedPolygon,
                  setSelectedPolygon,
                  setFormState
                );
                setApiLoading(false);
              })
              .catch(({ state }) => {
                setFormState({ ...state });
              })
          }
        >
          <div className="form-wrapper">
            <div className="form-row">
              <h4>Geozone Information</h4>
              <div className="grid">
                <div>
                  <p>Name</p>
                  <input
                    style={formState.name ? { border: '1px solid red' } : {}}
                    name="name"
                    type="text"
                    defaultValue={selectedPolygon?.data?.name || ''}
                    required
                    onChange={(e) => onChangeValidation(e, formState, setFormState)}
                  />
                  <span style={{ color: 'red' }}>{formState.name}</span>
                </div>
                <div>
                  <p>Location Type</p>
                  <div className="type" onClick={() => setIsTypeBoxOpen(!isTypeBoxOpen)}>
                    <span>{typeList.find((item) => item.key === selectedType)?.name}</span>
                    <div className="icons">
                      <FontAwesomeIcon
                        icon={faClose}
                        fontSize={13}
                        onClick={() => setSelectedType('')}
                      />
                      <FontAwesomeIcon
                        icon={isTypeBoxOpen ? faCaretUp : faCaretDown}
                        fontSize={13}
                        onClick={() => setIsTypeBoxOpen(!isTypeBoxOpen)}
                      />
                    </div>
                  </div>
                  <div
                    className="type-search"
                    style={isTypeBoxOpen ? { display: 'block' } : { display: 'none' }}
                  >
                    <div className="search-input">
                      <input type="text" onChange={(e) => setTypeSearch(e.target.value)} />
                      <FontAwesomeIcon icon={faMagnifyingGlass} fontSize={14} />
                    </div>
                    <div className="type-list">
                      {typeList
                        .filter((item) =>
                          item.name.toLowerCase().includes(typeSearch?.toLowerCase())
                        )
                        .sort((a, b) =>
                          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
                        )
                        .map((item) => (
                          <div
                            key={item.key}
                            style={
                              selectedType === item.key ? { backgroundColor: '#eeeeee' } : null
                            }
                            className="type-list-item"
                            onClick={() => {
                              setSelectedType(item.key);
                              setIsTypeBoxOpen(false);
                            }}
                          >
                            {item.name}
                          </div>
                        ))}
                    </div>
                  </div>
                  <input type="hidden" name="type" value={selectedType || ''} required />
                  <span style={{ display: selectedType ? 'none' : 'block', color: 'red' }}>
                    Type is required.
                  </span>
                </div>
                <div>
                  <p>Color</p>
                  <div className="color-input">
                    <input
                      id="colorInput"
                      style={formState.color ? { border: '1px solid red' } : {}}
                      name="color"
                      type="color"
                      defaultValue={selectedPolygon?.data?.color || '#000000'}
                      required
                      onChange={(e) => {
                        onChangeValidation(e, formState, setFormState);
                        setDisplayColor(e.target.value);
                      }}
                    />
                    <div onClick={() => document.getElementById('colorInput').click()}>
                      {displayColor}
                    </div>
                  </div>

                  <span style={{ color: 'red' }}>{formState.color}</span>
                </div>
              </div>
            </div>
            <div className="form-row">
              <h4>Speed</h4>
              <div className="grid">
                <div>
                  <p>Speed Limit </p>
                  <div className="input-with-suffix">
                    <input
                      style={formState.speed ? { border: '1px solid red' } : {}}
                      name="speed"
                      type="number"
                      step="0.5"
                      defaultValue={convertUnit(
                        'speed',
                        selectedPolygon?.data?.speedLimit?.limit,
                        unitSystem
                      ).toFixed(1)}
                      onChange={(e) => {
                        const value = e?.target?.value;

                        if (value && formState.speed?.length > 0) {
                          setFormState({
                            ...formState,
                            speed: '',
                          });
                        }
                      }}
                      min={0}
                    />
                    <span className="suffix"> {unitSystem === 'metric' ? 'km/h' : 'mph'}</span>
                  </div>
                  <span style={{ color: 'red' }}>{formState.speed}</span>
                </div>
                <div>
                  <p>Grace Period</p>
                  <div className="input-with-suffix">
                    <input
                      style={formState.gracePeriod ? { border: '1px solid red' } : {}}
                      name="gracePeriod"
                      type="number"
                      step="1"
                      defaultValue={selectedPolygon?.data?.speedLimit?.gracePeriod}
                      min={0}
                      onChange={(e) => {
                        const value = e?.target?.value;

                        if (value && formState.gracePeriod?.length > 0) {
                          setFormState({
                            ...formState,
                            gracePeriod: '',
                          });
                        }
                      }}
                    />
                    <span className="suffix"> seconds</span>
                    <span style={{ color: 'red' }}>{formState.gracePeriod}</span>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <button
                    className="toggle-button"
                    type="button"
                    onClick={() => setShowCoordinates(!showCoordinates)}
                    disabled={apiLoading}
                  >
                    {showCoordinates ? 'Hide' : 'Show'} coordinates
                  </button>
                  <FontAwesomeIcon icon={showCoordinates ? faCaretUp : faCaretDown} />
                </div>
                <div
                  className="coordinates"
                  style={{ display: showCoordinates ? 'block' : 'none' }}
                >
                  {polygonCoordinates.map((coordinate, index) => (
                    <div className="coordinates__row" key={index}>
                      <div>
                        <input
                          style={
                            formState.coordinates && !coordinate[0]
                              ? { border: '1px solid red' }
                              : {}
                          }
                          type="number"
                          step="1"
                          value={coordinate[0] || ''}
                          onChange={(e) => {
                            const newCoordinates = polygonCoordinates.map((coord, idx) =>
                              idx === index ? [parseFloat(e.target.value), coord[1]] : coord
                            );

                            setPolygonCoordinates(newCoordinates);
                          }}
                        />
                        <span style={{ color: 'red' }}>
                          {coordinate[0] ? '' : formState.coordinates}
                        </span>
                      </div>

                      <div>
                        <input
                          style={
                            formState.coordinates && !coordinate[1]
                              ? { border: '1px solid red' }
                              : {}
                          }
                          type="number"
                          step="1"
                          value={coordinate[1]}
                          onChange={(e) => {
                            const newCoordinates = polygonCoordinates.map((coord, idx) =>
                              idx === index ? [coord[0], parseFloat(e.target.value)] : coord
                            );

                            setPolygonCoordinates(newCoordinates);
                          }}
                        />
                        <span style={{ color: 'red' }}>
                          {coordinate[1] ? '' : formState.coordinates}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                resetTypeList({ locationTypes, setTypeList, setTypeSearch, setIsTypeBoxOpen });
                closeModal(
                  setIsOpen,
                  setDrawMode,
                  selectedPolygon,
                  setSelectedPolygon,
                  setFormState
                );
              }}
              disabled={apiLoading}
            >
              Cancel
            </button>
            <button type="submit" disabled={apiLoading || !selectedType}>
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};
