import React, { useContext, useState, useEffect, useRef } from 'react';
import { faCaretDown, faCircleNotch, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';

import { TilesContext } from '../context/TilesContext';
import { createDateTime, formatDateTime, humanizeDate } from '../utils/dates';
import { convertToParticipants, getIconColor, getInitials } from '../utils/messages';
import { NO_MESSAGES_FOUND } from '../utils/constants';
import { apiGet, apiPost } from '../utils/api';

import '../css/Messenger.css';

const sendData = async ({ api, content, recipient, sender, setMessage, setMessages, token }) => {
  const payload = {
    content,
    recipient,
    sender,
    date: createDateTime().toUTC(),
  };

  const msgUrl = `${api.originUrl}/api/messaging/messages`;

  const message = await apiPost({ url: msgUrl, token, body: payload });

  if (!message) {
    return;
  }

  setMessages((prevState) => prevState.concat([message]));
  setMessage('');
};

const Messenger = () => {
  const { entity, api, token, messages, users, currentSystemOu, currentSystemUser, setMessages } =
    useContext(TilesContext);

  const [oldestDate, setOldestDate] = useState(createDateTime().minus({ days: 1 }));
  const [loading, setLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [message, setMessage] = useState('');

  const latestMsgRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (loading) {
      const newDate = oldestDate.minus({ days: 5 });
      const start = newDate.toUTC();
      const end = oldestDate.toUTC();

      const msgUrl = `${api.originUrl}/api/messaging/messages${
        start && end ? `?end=${end}&start=${start}&participant=${entity?.key}` : ''
      }`;

      apiGet({ url: msgUrl, token })
        .then((response) => {
          setOldestDate(newDate);

          setMessages((prevState) => {
            if (!isMounted) return [];
            return prevState.concat(response);
          });
        })
        .finally(() => {
          setLoading(false);
          scrollToRef();
        });
    }
    return () => {
      isMounted = false;
    };
  }, [loading]);

  useEffect(() => {
    scrollToRef();
  }, [messages]);

  useEffect(() => {
    if (sendMessageLoading) {
      setTimeout(() => {
        setSendMessageLoading(false);
      }, 1000);
    }
  }, [sendMessageLoading]);

  const scrollToRef = () => {
    latestMsgRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const participants = convertToParticipants(users, currentSystemOu, messages);
  const { conversations } =
    participants?.find(({ reference }) => reference === entity?.driver?.key) || {};
  const sortedMessages = sortBy(conversations?.messages, ['date']);

  return (
    <div className="Messenger">
      <div className="Messenger__header">
        <span>{entity?.driver?.driverName}</span>
        <FontAwesomeIcon
          className={loading ? 'fa-spin' : ''}
          icon={loading ? faCircleNotch : faCaretDown}
        />
        <span
          onClick={() => (loading ? null : setLoading(true))}
          style={loading ? { color: '#ef6c00' } : {}}
        >
          Load messages before {formatDateTime(oldestDate, 'MM/dd/yyyy')}{' '}
        </span>
      </div>

      {sortedMessages.length === 0 ? (
        <div className="Messenger__container">
          <div className="message">
            <div className="date">{NO_MESSAGES_FOUND}</div>
          </div>
        </div>
      ) : (
        <div className="Messenger__container">
          {sortedMessages.map(({ key, date, content, author, authorName, status }) => (
            <div
              className={author === currentSystemUser?.key ? 'message message--isMe' : 'message'}
              key={key}
            >
              <div className="date">{humanizeDate(date)}</div>
              <div className="content">
                <div className="avatar" style={{ backgroundColor: getIconColor(authorName) }}>
                  {getInitials(authorName)}
                </div>
                <div className="text">{content}</div>
              </div>
              <div className="status">{status}</div>
            </div>
          ))}
          <div ref={latestMsgRef} />
        </div>
      )}
      <div className="Messenger__send">
        <textarea
          className="Messenger__input"
          placeholder="Type a message..."
          onChange={(e) => setMessage(e?.target?.value)}
          value={message}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              setSendMessageLoading(true);
              sendData({
                api,
                content: message,
                recipient: entity?.key,
                sender: currentSystemUser?.key,
                setMessage,
                setMessages,
                token,
              });
            }
          }}
        />
        <button
          id="sendButton"
          type="button"
          onClick={() => {
            setSendMessageLoading(true);
            sendData({
              api,
              content: message,
              recipient: entity?.key,
              sender: currentSystemUser?.key,
              setMessage,
              setMessages,
              token,
            });
          }}
          disabled={sendMessageLoading}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
    </div>
  );
};

export default Messenger;
