/* eslint-disable no-console */
import { ALPHA } from './alpha';
import { BETA } from './beta';
import { DELTA } from './delta';
import { DEVELOPMENT } from './development';
import { LOCAL } from './local';
import { MASTER } from './master';
import { OMEGA } from './omega';
import { PRODUCTION } from './production';
import { SANDBOX } from './sandbox';
import { STAGING } from './staging';

function getEnvByHost() {
  console.groupCollapsed('WEB VIEW INFO - [ENVIRONMENT]');
  let environment = 'LOCAL';

  if (
    window === undefined ||
    window.location === undefined ||
    window.location.hostname === undefined
  ) {
    console.error('Error in getEnvByHost: host is null or undefined');
    return environment;
  }

  const host = window.location.hostname;

  const tests = {
    LOCAL: /localhost/gi,
    DEVELOPMENT: /nav2dev\.ca/gi,
    MASTER: /nav2qa\.ca/gi,
    STAGING: /staging\.navistream\.ca/gi,
    SANDBOX: /sandbox\.mynavistream\.com/gi,
    OMEGA: /omega\.mynavistream\.com/gi,
    DELTA: /delta\.mynavistream\.com/gi,
    BETA: /beta\.mynavistream\.com/gi,
    ALPHA: /alpha\.mynavistream\.com/gi,
    PRODUCTION: /www\.mynavistream\.com/gi,
  };

  if (!tests || !host) {
    console.error('Error in getEnvByHost: tests or host is null or undefined');
    return environment;
  }

  try {
    Object.keys(tests).some((key) => {
      if (tests[key].test(host)) {
        environment = key;
        return true;
      }
      return false;
    });
  } catch (error) {
    console.error('Error in getEnvByHost: ', error);
    return environment;
  }

  console.log('returning host: ', host);
  console.log('returning environment: ', environment);
  console.groupEnd();
  return environment;
}

export function getEnvironment() {
  switch (getEnvByHost()) {
    case 'ALPHA':
      return ALPHA;
    case 'BETA':
      return BETA;
    case 'DELTA':
      return DELTA;
    case 'DEVELOPMENT':
      return DEVELOPMENT;
    case 'LOCAL':
      return LOCAL;
    case 'MASTER':
      return MASTER;
    case 'OMEGA':
      return OMEGA;
    case 'PRODUCTION':
      return PRODUCTION;
    case 'SANDBOX':
      return SANDBOX;
    case 'STAGING':
      return STAGING;
    default:
      return LOCAL;
  }
}
