import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import { useMap } from '@vis.gl/react-google-maps';

import { EntityContext } from '../context/EntityContext';
import { STORAGE_KEYS, MAP_SETTING_OPTION, DEFAULT_LOCATION } from '../utils/constants';

const Config = ({ onClose }) => {
  const { locationTypes, locations, setFilteredLocations, entity } = useContext(EntityContext);
  const settings = JSON.parse(localStorage.getItem(STORAGE_KEYS.settings));

  const [options, setOptions] = useState(uniq(settings));

  const map = useMap();

  const locationTypesKeys = uniq(locationTypes?.map(({ key }) => key));

  const onApply = () => {
    localStorage.setItem(STORAGE_KEYS.settings, JSON.stringify(options));

    const filteredLocations = locations.filter((location) => options.includes(location.type));

    setFilteredLocations(filteredLocations);

    const location = entity?.vehicle?.location || DEFAULT_LOCATION;
    map.setCenter(location);

    onClose();
  };

  return (
    <div className="config__modal">
      <div className="wrapper">
        <div className="column">
          {MAP_SETTING_OPTION.map(({ label, value }, id) => (
            <div className="item" key={id}>
              <span>{label}</span>
              <input
                type="checkbox"
                checked={options.includes(value)}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    setOptions([...options, value]);
                  } else {
                    setOptions(options.filter((item) => item !== value));
                  }
                }}
              />
            </div>
          ))}
        </div>
        <div className="column">
          <div className="mapSetting">
            <div className="item">
              <span>Show All Location Types</span>
              <input
                type="checkbox"
                checked={options.includes('showLocationLabels')}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    setOptions([...options, 'showLocationLabels']);
                  } else {
                    setOptions(options.filter((item) => item !== 'showLocationLabels'));
                  }
                }}
              />
            </div>
            {options.includes('showLocationLabels') &&
              locationTypes.map(({ name, key }, id) => {
                const isChecked = options.includes(key);

                return (
                  <div className="item" key={id}>
                    <span>{name}</span>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => {
                        const newState = e.target.checked;

                        if (newState) {
                          setOptions([...options, key]);
                        } else {
                          setOptions(options.filter((item) => item !== key));
                        }
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="selection">
        <span onClick={() => setOptions(uniq([...settings, ...locationTypesKeys]))}>
          Select all
        </span>
        <span
          onClick={() => setOptions(settings.filter((item) => !locationTypesKeys.includes(item)))}
        >
          Remove all
        </span>
      </div>
      <div className="footer">
        <span onClick={() => onClose()}>Cancel</span>
        <span onClick={() => onApply()}>Apply</span>
      </div>
    </div>
  );
};

Config.propTypes = {
  onClose: PropTypes.func,
};

export default Config;
