import { isEmpty } from 'lodash';

import { CATEGORIES } from './constants';

const activityFilter = (row, hideInactive) => (hideInactive ? row.isActive === true : true);

export const setFilterByCategory = (row, category, search, hideInactive) => {
  if (category === CATEGORIES.DRIVER) {
    return !isEmpty(search)
      ? row.driver?.driverName?.toLowerCase().includes(search?.toLowerCase()) &&
          row.category === CATEGORIES.DRIVER &&
          activityFilter(row, hideInactive)
      : row.category === CATEGORIES.DRIVER && activityFilter(row, hideInactive);
  }

  if (category === CATEGORIES.VEHICLE) {
    return !isEmpty(search)
      ? row?.vehicle?.vehicleId?.toLowerCase().includes(search.toLowerCase()) &&
          row.category === CATEGORIES.VEHICLE &&
          activityFilter(row, hideInactive)
      : row.category === CATEGORIES.VEHICLE && activityFilter(row, hideInactive);
  }

  const trailerNames = row?.trailers?.map((trailer) => trailer?.vehicleId.toLowerCase());

  return !isEmpty(search)
    ? trailerNames?.some((trailerName) => trailerName.includes(search.toLowerCase())) &&
        row.category === CATEGORIES.TRAILERS &&
        activityFilter(row, hideInactive)
    : row.category === CATEGORIES.TRAILERS && activityFilter(row, hideInactive);
};
