import { castArray, cloneDeep, uniqBy, findIndex, isObject, each, isArray } from 'lodash';

const deepMergeFn = (originalItem, newItem) => mergeObjects({ ...originalItem }, newItem);

function mergeObjects(target, source) {
  const clone = cloneDeep(target);
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (typeof source[key] === 'object' && source[key] !== null) {
        if (Array.isArray(source[key])) {
          clone[key] = uniqBy([...(clone[key] || []), ...source[key]], (item) =>
            isObject(item) ? JSON.stringify(item) : item
          );
        } else {
          clone[key] = mergeObjects(clone[key] || {}, source[key]);
        }
      } else {
        clone[key] = source[key];
      }
    }
  }
  return clone;
}

const mergeEntities = (originalArray, newItemOrArray, key, mergeFn) => {
  const oldItems = [...originalArray];
  const processItems = castArray(newItemOrArray);
  const addItems = [];
  processItems.forEach((newItem) => {
    if (!isObject(newItem) || !newItem[key])
      throw new Error(
        `Entity to process is not an object or is missing key property. New item: ${JSON.stringify(
          newItem
        )}`
      );
    const index = findIndex(originalArray, { [key]: newItem[key] });
    if (index !== -1) {
      oldItems[index] = mergeFn(oldItems[index], newItem);
    } else {
      addItems.push(newItem);
    }
  });

  return oldItems.concat(addItems);
};

export function mapActivities(activities) {
  const outputActivities = [];
  each(activities, function iterateActivity(activity) {
    outputActivities.push(activity);

    if (activity.subActivities && isArray(activity.subActivities)) {
      each(activity.subActivities, function iterateSubActivity(subActivity) {
        outputActivities.push(subActivity);
      });
    }
  });
  return outputActivities;
}

export const deepMergeEntities = (originalArray, newItemOrArray, key = 'id') =>
  mergeEntities(originalArray, newItemOrArray, key, deepMergeFn);
